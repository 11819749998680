import React from "react";
import Testata from "../componenti/Testata";
import { MdSimCardDownload } from "react-icons/md";
import Slider from "../componenti/Slider";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";
const lingua = localStorage.getItem("lingua");

const Quality = () => {
  return (
    <>
    <Helmet>
      <title>{Traduci(lingua, "seo_qualita_title")}</title>
      <meta
        name="description"
        content={Traduci(lingua, "seo_qualita_description")}
      />
    </Helmet>
      <Testata
        sopraTitolo={Traduci(lingua, "quality_pre")}
        pagina="quality"
        titolo={Traduci(lingua, "quality_testata_title")}
      />

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="pr">
              <p className="lead">{Traduci(lingua, "quality_intro_title")}</p>
              <p>{Traduci(lingua, "quality_intro_text")}</p>
            </div>
            <div className="col-6">
              <Slider
                classeSlider="silusi-home"
                immagini={[
                  "/img/quality/L1050007.jpg",
                  "/img/quality/L1050098.jpg",
                  "/img/quality/L1050249.jpg",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="parallax-window parallax-quality"
          data-parallax="scroll"
          data-image-src="/img/parallax_L1040871.jpg"
        >
          <div className="parallax-content">
            <div className="pt">
              <div className="container">
                <div className="row">
                  <div className="width33">
                    <h2>Download</h2>
                    <p>{Traduci(lingua, "quality_download_txt")}</p>
                  </div>
                  <div className="width66">
                    <ul className="fusion-checklist fusion-checklist-1">
                      <li className="fusion-li-item">
                        <a
                          href="/download/CertificatoISO-Silusi.pdf"
                          target="_blank"
                        >
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>ISO 9001 SILUSI S.R.L.</p>
                          </div>
                        </a>
                      </li>
                      <li className="fusion-li-item">
                        <a
                          href="/download/SilusiGoretexGoldLevel.pdf"
                          target="_blank"
                        >
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>SILUSI GORE-TEX GOLD CERTIFICATION AGREEMENT</p>
                          </div>
                        </a>
                      </li>
                      <li className="fusion-li-item">
                        <a
                          href="/download/SilusiSilverLevel.pdf"
                          target="_blank"
                        >
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>
                              SILUSI GORE-TEX SILVER CERTIFICATION AGREEMENT
                            </p>
                          </div>
                        </a>
                      </li>
                      <li className="fusion-li-item">
                        <a href="/download/F_IAR_114628_Award1.pdf" target="_blank">
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>Certificazione audit WCA</p>
                          </div>
                        </a>
                      </li>
                      <li className="fusion-li-item">
                        <a
                          href="/download/CertificateSilusi.pdf"
                          target="_blank"
                        >
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>Certificato Certitex 9001 Silusi</p>
                          </div>
                        </a>
                      </li>
                      <li className="fusion-li-item">
                        <a
                          href="/download/CertificatoISOLusi2023.pdf"
                          target="_blank"
                        >
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>ISO 9001 LUSI UNION S.R.L.</p>
                          </div>
                        </a>
                      </li>
                      <li className="fusion-li-item">
                        <a
                          href="/download/SKM_C3320i200825143000.pdf"
                          target="_blank"
                        >
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>LUSI GORE-TEX CERTIFICATION AGREEMENT</p>
                          </div>
                        </a>
                      </li>
                      <li className="fusion-li-item">
                        <a href="/download/Lusi1.pdf" target="_blank">
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>Certificato IQNet 9001 Lusi</p>
                          </div>
                        </a>
                      </li>
                      <li className="fusion-li-item">
                        <a href="/download/Lusi2.pdf" target="_blank">
                          <span className="material-icons orange600">
                            <MdSimCardDownload />
                          </span>
                          <div className="fusion-li-item-content">
                            <p>Certificato Certitex 9001 Lusi</p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div style={{ textAlign: "center" }}>
            <a
              className="calendario-dl"
              href="/download/Calendario_2023.pdf"
              target="_blank"
            >
              <div className="calendario-dl-img">
                <img src="/img/azienda/calendario2023.jpg" alt="" />
              </div>
              <div className="calendario-dl-txt">
                {Traduci(lingua, "quality_calendar_dl")}
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quality;
