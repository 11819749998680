import React from "react";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import { useEffect, useState } from "react";

const OdoNumber = (props) => {
  const { isVisible, numero } = props;
  const [count, setCount] = useState(0);
  useEffect(() => {
    isVisible && setCount(numero);
  }, [isVisible]);

  return isVisible ? (
    <Odometer animation="count" value={count} format="(.ddd),dd" />
  ) : null;
};

export default OdoNumber;
