import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  EffectFade,
  Pagination,
  Autoplay,
} from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";

SwiperCore.use([Navigation, Autoplay]);
const Slider = ({ classeSlider, immagini }) => {
  return (
    <div className={`react-slider ${classeSlider}`}>
      <Swiper
        modules={[EffectFade, Navigation, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          waitForTransition: true,
        }}
        navigation={true}
      >
        {immagini.map((img, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={img} alt="silusi" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Slider;
