import React, { useEffect, useMemo, useRef } from "react";
import { motion } from "framer-motion";
import gsap from "gsap";
import { links1, links2, links3 } from "./links";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/logo-h.png";

const HamburgerMenu = ({ toggle1, toggle2, toggleClasse }) => {
  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);
  let navigate = useNavigate();
  const divRef1 = useRef(0);
  const divRef2 = useRef(0);
  const divRef3 = useRef(0);

  useEffect(() => {
    timeline
      .from(divRef1.current.childNodes, {
        y: 100,
        opacity: 0,
        ease: "power4.inOut",
        duration: 0.4,
        stagger: 0.1,
      })
      .from(divRef2.current.childNodes, {
        y: 100,
        opacity: 0,
        ease: "power4.inOut",
        duration: 0.4,
        stagger: 0.1,
      })
      .from(divRef3.current.childNodes, {
        y: 100,
        opacity: 0,
        ease: "power4.inOut",
        duration: 0.4,
        stagger: 0.1,
      });
  }, []);

  useEffect(() => {
    if (toggle1) {
      timeline.play();
    } else {
      timeline.reverse();
    }
  }, [toggle1]);

  const menuRoute = (link) => {
    toggleClasse();
    navigate(link.url, { replace: true });
  };

  return (
    <>
      <div id="menu" className={toggle2 ? "open" : ""}>
        <button
          className={toggle1 ? "toggle-menu active" : "toggle-menu"}
          onClick={toggleClasse}
        >
          <span></span>
        </button>
        <div className="main-nav">
          <div className="container-menu">
            <motion.div
              className="menu-logo"
              drag
              dragConstraints={{ left: 0, top: 0, right: 0, bottom: 0 }}
              dragElastic={0.7}
            >
              <img src={logo} alt=" " />
            </motion.div>
            <div className="nav-wrapper">
              <nav className="main-nav-menu">
                <ul ref={divRef1}>
                  {links1.map((link, index) => (
                    <li key={index}>
                      <button onClick={() => menuRoute(link)}>
                        {link.label}
                      </button>
                    </li>
                  ))}
                </ul>
                <ul ref={divRef2}>
                  {links2.map((link, index) => (
                    <li key={index}>
                      <button onClick={() => menuRoute(link)}>
                        {link.label}
                      </button>
                    </li>
                  ))}
                </ul>
                <ul ref={divRef3}>
                  {links3.map((link, index) => (
                    <li key={index}>
                      <button onClick={() => menuRoute(link)}>
                        {link.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HamburgerMenu;
