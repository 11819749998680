import React from "react";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollTo from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollTo);

const BannerBW = () => {
  const text = useRef({ value: 1 });

  useEffect(() => { 

    gsap
      .timeline({
        defaults: {
          scrollTrigger: {
            anticipatePin: 1,
            trigger: "#section4",
            scrub: 0.5,
            pin: true,
          },
        },
      })
      .from("#section4 .clipSecond", {
        clipPath: "inset(0 0 0 100%)",
      })
      .from("#section4 img", {
        scale: 1.2,
      });
   
  }, [text]); 

  return (
    <div id="section4" className="largeContent green">
      <div className="clipFirst">
        <img src="https://images.unsplash.com/photo-1476231682828-37e571bc172f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80" />
      </div>
      <div className="clipSecond">
        <img src="https://images.unsplash.com/photo-1476231682828-37e571bc172f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80" />
      </div>
    </div>
  );
};

export default BannerBW;
