import { traduzioni } from "./ita.js";
import { trad_eng } from "./eng.js";

export function Traduci(language_code, entry_slug) {
  if (language_code === "it") { 
    return traduzioni[entry_slug];
  }

  if (language_code === "en") {
    return trad_eng[entry_slug];
  }
}
