import { Traduci } from "../../traduzioni/language";

const lingua = localStorage.getItem("lingua");

export const links1 = [
  {
    label: Traduci(lingua, "menu_italia"),
    url: "/azienda/",
  },
  {
    label: Traduci(lingua, "menu_made_in_europe"),
    url: "/sedi-produttive/",
  },
  {
    label: Traduci(lingua, "manu_team"),
    url: "/il-nostro-team/",
  } 
];

export const links2 = [ 
  {
    label: Traduci(lingua, "menu_materiali"),
    url: "/materiali/",
  },
  {
    label: Traduci(lingua, "menu_lavorazioni"),
    url: "/lavorazioni/",
  },
  {
    label: Traduci(lingua, "menu_mercati"),
    url: "/settori/",
  },
];

export const links3 = [ 
  {
    label: Traduci(lingua, "menu_quality"),
    url: "/qualita/",
  },
  {
    label: Traduci(lingua, "menu_storia"),
    url: "/storia/",
  },  
  {
    label: Traduci(lingua, "menu_contatti"),
    url: "/contatti/",
  },
]; 
