import React from "react";
import Testata from "../componenti/Testata";
import YoutubePlayer from "../componenti/YoutubePlayer";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";
const lingua = localStorage.getItem("lingua");

const Mercati = () => {
  return (
    <>
      <Helmet>
        <title>{Traduci(lingua, "seo_settori_title")}</title>
        <meta
          name="description"
          content={Traduci(lingua, "seo_settori_description")}
        />
      </Helmet>

      <Testata
        sopraTitolo={Traduci(lingua, "mercati_pre")}
        pagina="mercati"
        titolo={Traduci(lingua, "mercati_testata_title")}
      />

      <div className="section-mercati">
        <div className="container">
          <div className="row">
            <div className="section-txt">
              <h2>{Traduci(lingua, "mercati_fashion_title")}</h2>
              {Traduci(lingua, "mercati_fashion_text")}
            </div>
            <div className="section-img">
              <YoutubePlayer videoUrl="2mmtQ668DHo" />
            </div>
          </div>
        </div>
      </div>

      <div className="section-mercati">
        <div className="container">
          <div className="row">
            <div className="section-txt">
              <h2>{Traduci(lingua, "mercati_outdoor_title")}</h2>
              {Traduci(lingua, "mercati_outdoor_text")}
            </div>
            <div className="section-img">
              <YoutubePlayer videoUrl="wm7Kwykg0Wc" />
            </div>
          </div>
        </div>
      </div>

      <div className="section-mercati">
        <div className="container">
          <div className="row">
            <div className="section-txt">
              <h2>{Traduci(lingua, "mercati_sport_title")}</h2>
              {Traduci(lingua, "mercati_sport_text")}
            </div>
            <div className="section-img">
              <YoutubePlayer videoUrl="krn5EujUb0I" />
            </div>
          </div>
        </div>
      </div>

      <div className="section-mercati">
        <div className="container">
          <div className="row">
            <div className="section-txt">
              <h2>{Traduci(lingua, "mercati_tempo_libero_title")}</h2>
              {Traduci(lingua, "mercati_tempo_libero_text")}
            </div>
            <div className="section-img">
              <YoutubePlayer videoUrl="TEq9KGY_gFo" />
            </div>
          </div>
        </div>
      </div>

      <div className="section-mercati">
        <div className="container">
          <div className="row">
            <div className="section-txt">
              <h2>{Traduci(lingua, "mercati_workwear_title")}</h2>
              {Traduci(lingua, "mercati_workwear_text")}
            </div>
            <div className="section-img">
              <YoutubePlayer videoUrl="-6GN3H9okVg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mercati;
