import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

const plusVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1, delayChildren: 0, staggerChildren: 0.6 },
  },
};

const imgVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const textVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const FluidContent = ({ titolo, testo, children }) => {
  return (
    <>
      <div className="pt pb fluid_content">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50 text_col">
              <h2>{titolo}</h2>
              <p>{testo}</p>
            </div>
            <div className="width50 children_col">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FluidContent;
