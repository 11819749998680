import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Traduci } from "../traduzioni/language";

const plusVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1, delayChildren: 0, staggerChildren: 0.2 },
  },
};

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const lingua = localStorage.getItem("lingua");

const Plus = () => {
  return (
    <motion.div
      whileInView="visible"
      viewport={{ once: false }}
      transition={{ duration: 0.3 }}
      variants={plusVariants}
      initial="hidden"
      className="home__plus pb"
    >
      <div className="text-center pb2">
        <div className="sup">{Traduci(lingua, "i_nostri_plus")}</div>
      </div>
      <div className="row">
        <div className="col-4">
          <motion.div className="card" variants={itemVariants}>
            <span className="home-icon">{/* <GiStarKey /> */}</span>
            <h4 className="h4title">{Traduci(lingua, "plus_title_1")}</h4>
            <p className="pdesc">{Traduci(lingua, "plus_text_1")}</p>
          </motion.div>
        </div>
        <div className="col-4">
          <motion.div className="card" variants={itemVariants}>
            <span className="home-icon">{/* <GiSafetyPin /> */}</span>
            <h4 className="h4title">{Traduci(lingua, "plus_title_2")}</h4>
            <p className="pdesc">{Traduci(lingua, "plus_text_2")}</p>
          </motion.div>
        </div>
        <div className="col-4">
          <motion.div className="card" variants={itemVariants}>
            <span className="home-icon">{/* <GiSoapExperiment /> */}</span>
            <h4 className="h4title">
            {Traduci(lingua, "plus_title_3")}
            </h4>
            <p className="pdesc">
            {Traduci(lingua, "plus_text_3")}
            </p>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Plus;
