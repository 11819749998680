import React, { useState } from "react";
import { motion } from "framer-motion";
import NavBar from "./NavBar";
import { NavLink } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import logo from "../assets/logo-h.png";

const svgVariants = {
  hidden: { rotate: -180 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

if(!localStorage.lingua) {
  localStorage.setItem("lingua", "it")
}

const Header = () => {
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const toggleClasse = (e) => {
    setToggle1(!toggle1);
    setToggle2(!toggle2);
  };

  const setLang = (lingua) => {
    localStorage.setItem("lingua", lingua)
    window.location.reload();
  };  

  return (
    <>
      <header id="header">
        <div className="container">
          <div className="header-row">
            <motion.div
              className="logo"
              drag
              dragConstraints={{ left: 0, top: 0, right: 0, bottom: 0 }}
              dragElastic={0.7}
            >
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "nav-active" : "")}
              >
                <img src={logo} alt=" " />
              </NavLink>
            </motion.div>

            <motion.div
              className="nav-right-title"
              initial={{ y: -250 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
            >
              <div className="menu-language">
                <button onClick={() => setLang("it")}>IT</button>
                <button onClick={() => setLang("en")}>EN</button>
              </div>
              <div className="menu-icon">
                <button
                  className={toggle1 ? "toggle-menu active" : "toggle-menu"}
                  onClick={toggleClasse}
                >
                  <span></span>
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      </header>
      <HamburgerMenu
        toggle1={toggle1}
        toggle2={toggle2}
        toggleClasse={toggleClasse}
      />
    </>
  );
};

export default Header;
