import React from "react";
import footerLogo from "../assets/logov.png";

const Footer = () => {
  return (
    <footer
      id="footer"
      role="contentinfo"
      data-aos="fade-in"
      className="aos-init aos-animate"
    >
      <div className="footer-content">
        <div className="container">
          <div className="footer-row">
            <div className="footer-left">
              <p>
                Via San Rocco n.19
                <br />
                24060 – Sovere (Bergamo) – Italia
              </p>

              <p>
                Tel. +39 035/982666
                <br />
                Fax +39 035/982656
              </p>

              <p>
                e-mail: <a href="mailto:info@silusi.com">info@silusi.com</a>
              </p>

              <ul className="footer-social">
                <li>
                  <a href="#" target="_blank">
                    <i className="lni lni-facebook-oval"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="lni lni-instagram-original"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-right">
              <div className="footer-logo">
                <img src={footerLogo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
