import React from "react";
import copyImg from "../assets/copyright.png";

const Copyright = () => {
  return (
    <div id="copyright">
      <div className="container">
        <div className="row space-between">
          <div className="powered">
            © Copyright Silusi S.r.l. | P. Iva: 01573320163 | All rights
            reserved - <a href="/privacy-policy.html" target="_blank">Privacy Policy</a>
          </div>
          <div className="copy-makemedia">
            <img src={copyImg} alt="copyright" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
