import React from "react";
import { motion } from "framer-motion";

const Testata = ({ titolo, pagina, sopraTitolo }) => {
  const titleWrapperVariants = {
    initial: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const titleVariants = {
    initial: {
      y: 100,
      opacity: 0,
      transition: {
        type: "spring",
        mass: 1,
        damping: 10,
        stiffness: 80,
      },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        damping: 10,
        stiffness: 80,
      },
    },
  };

  return (
    <div className={pagina ? `testata testata__${pagina.replace(/\s/g,'').toLowerCase()}` : `testata`}>
      <div className="container">
        <motion.div
          className="testata_content"
          variants={titleWrapperVariants}
          animate="visible"
          initial="initial"
        >
          <motion.div className="sup" variants={titleVariants}>
            {sopraTitolo}
          </motion.div>
          <motion.h1 variants={titleVariants}>{titolo}</motion.h1>
        </motion.div>
      </div>
    </div>
  );
};

export default Testata;
