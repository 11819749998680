import React from "react";
import Gallery from "../componenti/Gallery/Gallery";
import Plus from "../componenti/Plus";
import Testata from "../componenti/Testata";

import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";

const lingua = localStorage.getItem("lingua");

const Team = () => {
  return (
    <>
      <Helmet>
        <title>{Traduci(lingua, "seo_il_nostro_team_title")}</title>
        <meta
          name="description"
          content={Traduci(lingua, "seo_il_nostro_team_description")}
        />
      </Helmet>

      <Testata
        sopraTitolo={Traduci(lingua, "team_pre")}
        pagina="team"
        titolo={Traduci(lingua, "team_testata_title")}
      />

      <div className="pb">
        <div className="container">
          <div className="team-intro">
            <div className="team-left">
              <div className="team-text-1">
              {Traduci(lingua, "team_slogan")}
              </div>
            </div>
            <div className="team-right">
              <img src="/img/team/ExzxwP8WEAoEidc.jpg" alt="" />
            </div>
            <div className="team-bottom">
              <div className="team-txt-2">
                <p className="lead">
                {Traduci(lingua, "team_intro")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container">
          <div className="intro text-center pb">
            <div className="sup">
                {Traduci(lingua, "our_team")}</div>
            <h3 className="mb0">
                {Traduci(lingua, "team_text")}
            </h3>
          </div>

          <Plus />
        </div>
      </div>

      {/* <div className="silusi-storia">
        <div className="container">
          <div className="intro text-center">
            <div className="sup">La nostra storia</div>
            <div className="">
              <p className="lead">
                Nel 1976 dalla visione del fondatore Angelo Sigorini si avvia
                un’azienda familiare pronta per una nuova esperienza nel mondo
                tessile.
              </p>
              <p>
                Nel 1985 nasce ufficialmente la Silusi Srl che da azienda
                artigiana a carattere familiare e attiva a livello locale si
                trasforma in pochi anni, grazie a una mirata gestione del lavoro
                e all’esperienza acquisita, in un’azienda strutturata espandendo
                la propria attività a livello internazionale.
              </p>
              <p>
                L’azienda ha iniziato occupandosi della lavorazione di capi di
                abbigliamento da lavoro tecnici e con le competenze e le
                esperienze acquisite ha potuto specializzarsi in tutte le
                tipologie di lavorazione.
              </p>
              <p>
                Già alla fine degli anni ‘80 è stata certificata per la
                produzione di capi in GORE-TEX, per poi introdurre tecniche e
                tecnologie sempre più all’avanguardia per realizzare qualsiasi
                tipo di progetto con materiali innovativi. A prova della qualità
                del sistema di gestione dal 2001 Silusi srl è certificata UNI EN
                ISO 9001:2000.
              </p>
              <p>
                Dal 1991 inizia l’esperienza europea e parte della produzione
                viene affidata in Romania. Nel 1996 viene acquisita la sede
                produttiva di Tg Trotus e fondata la LUSI UNION SILUSI SRL per
                poter ampliare la capacità produttiva potendo gestire tutto il
                processo dalla ideazione alla produzione. Dal 2001 vengono
                aggiunte tre nuove linee produttive con la creazione della
                SILMADUM FASHION SRL, nel 2015 viene fondata la LUBOSIL SRL e
                nel 2022 CELITEX. Tutte i siti rumeni sono certificati UNI EN
                ISO 9001:2000 dal 1998.
              </p>
              <p>
                L’integrazione dell’intero processo produttivo unite ad
                esperienza, qualità e puntualità permettono di garantire una
                gestione completa del prodotto curata nei minimi dettagli. Oggi
                siamo partner affidabile per i migliori brand del fashion
                internazionale, dello sport e per gli stilisti emergenti perché
                siamo in grado di assicurare al Cliente la certezza di un
                prodotto finito della massima qualità.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="pt pb">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <img src="/img/team/white+.png" alt="" />
            </div>
            <div className="col-11">
              <br />
              <br />
              <h2>
                Una cura artigianale
                <br />
                del dettaglio
              </h2>
              <div className="team-center">
                <h3>
                  I nostri clienti possono contare su implementando le idee che
                  riceviamo con tutta la conoscenza tecnica necessaria al fine
                  di industrializzare al meglio tutti i prodotti.
                </h3>
                <h3>
                  Il totale controllo della fase di prototipazione ed
                  industrializzazione, ci consente di affrontare al meglio la
                  successiva fase di produzione in serie dei capi sviluppati in
                  Italia presso le singole unità produttive che negli anni si
                  sono moltiplicate.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="pt pb">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <img src="/img/team/white+.png" alt="" />
            </div>
            <div className="col-11">
              <br />
              <br />
              <h2>
                {Traduci(lingua, "team_title_2")}
              </h2>
              <div className="team-center">
                {Traduci(lingua, "team_text_2")}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Gallery
        immagini={[
          "L1130565",
          "L1130656",
          "L1130648",
          "L1130652",
          "L1130688",
          "L1130701",
          "L1130813",
          "L1130947",
          "L1130923h",
          "L1130916",
          "L1130949",
          "L1140020",
          "L1130987",
          "L1140031",
          "L1130947",
        ]}
      /> */}

      <Gallery
        immagini={[
          "L1080470",
          "L1080387",
          "L1080410",
          "L1080431",
          "L1080334",
          "L1080350",
          "L1080368",
          "L1080458",
          "L1080489",
          "L1080496",
          "L1080501",
          "L1080506",
          "L1050152",
          "L1050160",
          "L1050205",
          "L1050224",
          "L1130657",
          "L1130566",
          "L1130648",
          "L1130701",
          "L1130818",
          "L1130949",
          "L1130947",
          "L1130987",
          "L1130700",
          "L1130688",
          "L1130716",
          "L1130698",
          "L1130717",
          "L1130782",
        ]}
      />

      <Gallery
        customClass="people"
        immagini={[
          "people_3",
          "people_2",
          "people_1",
          "people_5",
          "people_6",
          "people_7",
          "people_8",
          "people_9",
          "people_11",
          "people_12",
          "people_13",
          "people_14",
          "people_15",
          "people_16",
          "people_17",
          "people_18",
          "people_19",
          "people_20",
        ]}
      />
    </>
  );
};

export default Team;
