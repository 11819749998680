import React, { useState, useRef } from "react";
import Chevron from "./Chevron";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion-icon");
  const content = useRef(null);

  const classeAccordion = props.titolo.replace(/\s+/g, '').toLowerCase();

  function scrollTo(classeDivScroll) {
    scroller.scrollTo(classeDivScroll, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -80
    })
  }
  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  function toggleAccordion(classeDivScroll) {
    // content.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    // window.scrollTo(0, content.current.offsetTop+50)
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion-icon" : "accordion-icon rotate"
    );

    scrollTo(classeDivScroll)
  }

  return (
    <div className="container">
      <div className={`accordion-section ${classeAccordion}`}>
        <button className={`accordion ${setActive}`} onClick={()=> toggleAccordion(classeAccordion)}>
          <p className="accordion-title">{props.titolo}</p>
          <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
        </button>
        <div
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
          className="accordion-content"
        >
          <div className="accordion-text">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default Accordion;
