import React from "react";
import Slider from "../componenti/Slider";
import Testata from "../componenti/Testata"; 
import YoutubePlayer from "../componenti/YoutubePlayer";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";

const lingua = localStorage.getItem("lingua");

const Materiali = () => {
  return (
    <>
      <Helmet>
        <title>{Traduci(lingua, "seo_materiali_title")}</title>
        <meta
          name="description"
          content={Traduci(lingua, "seo_materiali_description")}
        />
      </Helmet>

      <Testata
        sopraTitolo={Traduci(lingua, "materiali_pre")}
        pagina="materiali"
        titolo={Traduci(lingua, "materiali_testata_title")}
      />

      <div className="pb">
        <div className="container">
          <div className="row">
            <div className="width50">
              <h3>{Traduci(lingua, "materiali_intro")}</h3>
              <p className="lead">{Traduci(lingua, "materiali_intro_2")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50 pr">
              <h2>{Traduci(lingua, "tessuti_tecnici_title")}</h2>
              <p className="lead">{Traduci(lingua, "tessuti_tecnici_lead")}</p>

              {Traduci(lingua, "tessuti_tecnici_text")}
            </div>
            <div className="width50">
              <YoutubePlayer videoUrl="jBUZafeoB8s" />
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50">
              <YoutubePlayer videoUrl="2riVLtsPqQU" />
            </div>
            <div className="width50 pl">
              <h2>{Traduci(lingua, "tessuti_piuma_title")}</h2>
              <p className="lead">{Traduci(lingua, "tessuti_piuma_lead")}</p>
              <p>{Traduci(lingua, "tessuti_piuma_text")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50 pr">
              <h2>{Traduci(lingua, "tessuti_legno_title")}</h2>
              <p className="lead">{Traduci(lingua, "tessuti_legno_lead")}</p>
            </div>
            <div className="width50">
              <Slider
                classeSlider="silusi-legno"
                immagini={[
                  "/img/materiali/legno/L1000725.jpg",
                  "/img/materiali/legno/L1000727.jpg",
                  "/img/materiali/legno/L1000728.jpg",
                  "/img/materiali/legno/L1000731.jpg",
                  "/img/materiali/legno/L1000732.jpg",
                  "/img/materiali/legno/L1000733.jpg",
                  "/img/materiali/legno/L1000735.jpg",
                  "/img/materiali/legno/L1000737.jpg",
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50">
              <YoutubePlayer videoUrl="qv2x-ZQ3jVY" />
            </div>
            <div className="width50 pl">
              <h2>{Traduci(lingua, "tessuti_dyneema_title")}</h2>
              <p className="lead">{Traduci(lingua, "tessuti_dyneema_lead")}</p>
              {Traduci(lingua, "tessuti_dyneema_text")}
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50 pr">
              <h2>{Traduci(lingua, "tessuti_luminescenza_title")}</h2>
              <p className="lead">
                {Traduci(lingua, "tessuti_luminescenza_lead")}
              </p>
              <p>{Traduci(lingua, "tessuti_luminescenza_text")}</p>
            </div>
            <div className="width50">
              <YoutubePlayer videoUrl="5t1QDipQ4I0" />
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50">
              <YoutubePlayer videoUrl="3Um6btFuLzo" />
            </div>
            <div className="width50 pl">
              <h2>{Traduci(lingua, "tessuti_fluorescenza_title")}</h2>
              <p className="lead">
                {Traduci(lingua, "tessuti_fluorescenza_lead")}
              </p>
              <p>{Traduci(lingua, "tessuti_fluorescenza_text")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50 pr">
              <h2>{Traduci(lingua, "tessuti_metallo_title")}</h2>
              <p className="lead">{Traduci(lingua, "tessuti_metallo_lead")}</p>
              <p>{Traduci(lingua, "tessuti_metallo_text")}</p>
            </div>
            <div className="width50">
              <Slider
                classeSlider="silusi-metallo"
                immagini={[
                  "/img/materiali/metallo/full-metal-s-1378-12-688x388.jpg",
                  "/img/materiali/metallo/full-metal-s-1378-575-688x388.jpg",
                  "/img/materiali/metallo/full-metal-s-1378-676-688x388.jpg",
                  "/img/materiali/metallo/full-metal-s-1378-801-688x388.jpg",
                  "/img/materiali/metallo/full-metal-s-1378-806-688x388.jpg",
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50">
              <img src="/img/materiali/MarsJacketPants_Dark_1378_28.jpg" />
            </div>
            <div className="width50 pl">
              <h2>{Traduci(lingua, "tessuti_nylon_title")}</h2>
              <p className="lead">{Traduci(lingua, "tessuti_nylon_lead")}</p>
              {Traduci(lingua, "tessuti_nylon_text")}
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb">
        <div className="container">
          <div className="row align-items-center">
            <div className="width50 pr">
              <h2>{Traduci(lingua, "tessuti_lane_title")}</h2>
              <p className="lead">{Traduci(lingua, "tessuti_lane_lead")}</p>
              <p>{Traduci(lingua, "tessuti_lane_text")}</p>
            </div>
            <div className="width50">
              <Slider
                classeSlider="silusi-metallo"
                immagini={[
                  "/images/L1000706.jpg",
                  "/images/L1000707.jpg",
                  "/images/L1000708.jpg",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Materiali;
