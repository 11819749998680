import React from "react";
import Gallery from "../componenti/Gallery/Gallery";
import Plus from "../componenti/Plus";
import Testata from "../componenti/Testata";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";
const lingua = localStorage.getItem("lingua");

const Storia = () => {
  return (
    <>
      <Helmet>
        <title>{Traduci(lingua, "seo_storia_title")}</title>
        <meta
          name="description"
          content={Traduci(lingua, "seo_storia_description")}
        />
      </Helmet>

      <Testata
        sopraTitolo={Traduci(lingua, "storia_pre")}
        pagina="LA NOSTRA STORIA"
        titolo={Traduci(lingua, "storia_testata_title")}
      />

      <div className="pt">
        <div className="container">
          <p>{Traduci(lingua, "storia_text_1")}</p>
        </div>
      </div>

      <div className="pt">
        <div className="container">
          <div className="history-intro">
            <div className="history-text-1">
              {Traduci(lingua, "storia_text_2")}
            </div>
            <div className="history-bottom">
              <p className="lead">{Traduci(lingua, "storia_text_3")}</p>
            </div>
          </div>
        </div>
      </div>

      <Gallery immagini={["sigorini-angelo", "L1080442", "L1080481"]} />

      <div className="pt pb">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <img src="/img/team/white+.png" alt="" />
            </div>
            <div className="col-11">
              <br />
              <br />
              <h5>{Traduci(lingua, "storia_text_4")}</h5>
              <div className="team-center">
                <h4>{Traduci(lingua, "storia_text_5")}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb">
        <div className="container">
          <div className="row">
            <div className="col-6 pr2">{Traduci(lingua, "storia_text_6")}</div>
            <div className="col-6 pl2">
              <p>{Traduci(lingua, "storia_text_7")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Storia;
