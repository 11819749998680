import React from "react";

const ParallaxMobile = ({ image, title, children }) => {
  return (
    <div className="parallax_mobile">
      <div className="container">
        <div className="parallax_mobile_content">
          <img src={image} alt={title} />
          <h2>{title}</h2>
          <div className="buttons_pm">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ParallaxMobile;
