import {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  useRoutes,
} from "react-router-dom";
import "./App.scss";
import Copyright from "./componenti/Copyright";
import Footer from "./componenti/Footer";
import Header from "./componenti/Header";
import Quality from "./pagine/Quality";
import Team from "./pagine/Team";
import Mercati from "./pagine/Mercati";
import Azienda from "./pagine/Azienda";
import Materiali from "./pagine/Materiali";
import Lavorazioni from "./pagine/Lavorazioni";
import Home from "./pagine/Home";
import Contatti from "./pagine/Contatti";
import { ParallaxProvider } from "react-scroll-parallax";
import ScrollToTop from "./componenti/ScrollToTop";
import SediProduttive from "./pagine/SediProduttive";
import Storia from "./pagine/Storia";

const AppRoutes = () => {
  return useRoutes([
    // { path: "realizzazioni", element: <Realizzazioni /> },
    // { path: "realizzazioni/:id", element: <RealizzazioneDettaglio /> },
    { path: "home", element: <Home /> },
    { path: "azienda", element: <Azienda /> },
    { path: "lavorazioni", element: <Lavorazioni /> },
    { path: "quality", element: <Quality /> },
    { path: "qualita", element: <Quality /> },
    { path: "team", element: <Team /> },
    { path: "il-nostro-team/", element: <Team /> },
    { path: "mercati", element: <Mercati /> },
    { path: "settori", element: <Mercati /> },
    { path: "materiali", element: <Materiali /> },
    { path: "contattaci", element: <Contatti /> },
    { path: "contatti", element: <Contatti /> },
    { path: "sedi-produttive", element: <SediProduttive /> },
    { path: "storia", element: <Storia /> },
    { path: "/", element: <Navigate to="home" /> },
    {
      path: "*",
      element: (
        <div>
          <p>Questa pagina non esiste.</p>
          <Link to="/">Torna alla home</Link>
        </div>
      ),
    },
    // {
    //   path: 'login',
    //   element: <Login />,
    //   children: [
    //     { index: true, element: <SignIn /> },
    //     { path: 'registration', element: <Registration /> },
    //     { path: 'lostpass', element: <LostPass /> },
    //   ]
    // },
  ]);
};

function App() {
  return (
    <ParallaxProvider>
      <BrowserRouter>
      <ScrollToTop />
        <Header />
        <AppRoutes />
        <Footer />
        <Copyright />
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
