import React from "react";
import ViewportTracker from "viewport-tracker";
import OdoNumber from "./Animazioni/OdoNumber";

const SediOdometer = ({ numeri }) => {
  return (
    <div className="stats-sedi">
      {numeri.map((numero, chiave) => {
        return (
          <div key={chiave}>
            <ViewportTracker>
              <OdoNumber numero={numero.numero} />
              <span className="udm">{numero.unita}</span>
            </ViewportTracker>
            <h5>{numero.titolo}</h5>
          </div>
        );
      })}
    </div>
  );
};

export default SediOdometer;
