import React from "react";
import ViewportTracker from "viewport-tracker";
import OdoNumber from "./Animazioni/OdoNumber";

const SectionOdometer = ({ numeri }) => {
  return (
    <div className="pb">
      <div className="container">
        <div className="stats">
          {numeri.map((numero, chiave) => {
            return (
              <div key={chiave}>
                <ViewportTracker>
                  <OdoNumber numero={numero.numero}  />
                  <span className="udm">{numero.unita}</span>
                </ViewportTracker>
                <h5>{numero.titolo}</h5>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionOdometer;
