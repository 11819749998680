import React from "react";
import { Link, NavLink } from "react-router-dom";

const Button = ({ text, link }) => {
  return (
    <Link to={link}>
      <div className="button">
        <span className="button__mask"></span>
        <span className="button__text">{text}</span>
        <span className="button__text button__text--bis">{text}</span>
      </div>
    </Link>
  );
};

export default Button;
