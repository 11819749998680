import React from "react";

import YoutubeBackground from "react-youtube-background";

import { ParallaxBanner } from "react-scroll-parallax";

import Button from "../componenti/Button";
import Slider from "../componenti/Slider";
import Plus from "../componenti/Plus";
import SectionOdometer from "../componenti/SectionOdometer";
import FadeIn from "../componenti/Animazioni/FadeIn";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";
import ParallaxMobile from "../componenti/ParallaxMobile";

const lingua = localStorage.getItem("lingua");

const Home = () => {
  return (
    <>
    <Helmet>
      <title>{Traduci(lingua, "seo_home_title")}</title>
      <meta name="description" content={Traduci(lingua, "seo_home_description")} />
    </Helmet>
      <YoutubeBackground
        videoId={"hSWWgYuxdz4"} /* default -> null */
        aspectRatio={"16:9"} /* default -> "16:9" */
        playerOptions={{ mute: 1, loop: 8, autoplay: 0 }}
        className={"video-bg"} /* default -> null */
        nocookie={true}
      >
        <div className="video-home-overlay">
          <div className="home-caption">
            <div className="container">
              <h1>{Traduci(lingua, "titolo_slideshow")}</h1>
              <div className="sup">
              {Traduci(lingua, "sottotitolo_slideshow")}
              </div>
            </div>
          </div>
          <div className="icon-scroll"></div>
        </div>
      </YoutubeBackground>

      <>
        <div className="pt pb">
          <div className="container">
            <div className="row align-items-center">
              <div className="pr col-6">
                <h4>
                {Traduci(lingua, "silusi_partner")}
                </h4>
                <h5>
                {Traduci(lingua, "silusi_partner_sottotiolo")}
                </h5>
              </div>
              <div className="col-6">
                <Slider
                  classeSlider="silusi-home"
                  immagini={[
                    "/img/slider-home/L1040755-1024x682.jpg",
                    "/img/slider-home/L1050168-1024x682.jpg",
                    "/images/L1130811.jpg",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </>

      <SectionOdometer
        numeri={[
          { numero: 10, titolo: Traduci(lingua, "stats_siti_produttivi") } ,
          { numero: 1160, titolo: Traduci(lingua, "stats_dipendenti") },
          { numero: 350000, titolo: Traduci(lingua, "stats_capi_prodotti") },
        ]}
      />

      <ParallaxBanner
        layers={[
          { image: "/img/parallax.jpg", speed: 28 },
          {
            speed: 38,
            children: (
              <div className="parallax-overlay">
                <div className="parallax-caption">
                  <h1>
                  {Traduci(lingua, "home_parallax_title")}
                  </h1>
                  <div className="buttons">
                    <Button text={Traduci(lingua, "button_link_lavorazioni")} link="/lavorazioni" />
                  </div>
                </div>
              </div>
            ),
          },
        ]}
        className="parallax-banner"
      ></ParallaxBanner>

      <ParallaxMobile
        image={"/img/parallax.jpg"}
        title={Traduci(lingua, "home_parallax_title")}
      >
        <Button text={Traduci(lingua, "button_link_lavorazioni")} link="/lavorazioni" />
      </ParallaxMobile>

      <div className="" data-aos="fade-in">
        <div className="container">
          <div className="intro text-center pt pb">
            <div className="sup">{Traduci(lingua, "pre_our_team")}</div>
            <h3 className="mb0">
            {Traduci(lingua, "our_team_title")}
            </h3>
            <div className="buttons">
              <Button text={Traduci(lingua, "button_read_more")} link="/azienda/" />
            </div>
          </div>

          <Plus />
        </div>
      </div>

      <ParallaxBanner
        layers={[{ image: "/img/parallax_L1040871.jpg", speed: -18 }]}
      >
        <div className="parallax-quality">
          <div className="parallax-content pt pb">
            <div className="pt pb">
              <div className="container">
                <div className="row">
                  <div className="width33">
                    <h2>{Traduci(lingua, "banner_quality_title")}</h2>
                    <div className="buttons">
                      <Button text={Traduci(lingua, "button_vedi_tutte")} link="/qualita" />
                    </div>
                  </div>
                  <div className="width66">
                    <ul>
                      <li>
                        <h5>GORE-TEX Certification</h5>
                        GOLD LEVEL Silusi
                      </li>
                      <li>
                        <h5>GORE-TEX Certification</h5>
                        SILVER LEVEL Silusi
                      </li>
                      <li>
                        <h5>Certificato Certitex</h5>
                        Silusi
                      </li>
                      <li>
                        <h5>Certificato Certitex</h5>
                        Lusi
                      </li>
                      <li>
                        <h5>ISO 9001</h5>
                        Silusi S.r.l.
                      </li>
                      <li>
                        <h5>ISO 9001</h5>
                        Lusi union S.r.l.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ParallaxBanner>
    </>
  );
};

export default Home;
