import React from "react";
import FluidContent from "../componenti/FluidContent";
import Testata from "../componenti/Testata";
import VideoPlayer from "../componenti/VideoPlayer";
import YoutubePlayer from "../componenti/YoutubePlayer";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";
const lingua = localStorage.getItem("lingua");

const Lavorazioni = () => {
  return (
    <>
      <Helmet>
        <title>{Traduci(lingua, "seo_lavorazioni_title")}</title>
        <meta
          name="description"
          content={Traduci(lingua, "seo_lavorazioni_description")}
        />
      </Helmet>

      <Testata
        sopraTitolo={Traduci(lingua, "lavorazioni_pre")}
        pagina="lavorazioni"
        titolo={Traduci(lingua, "lavorazioni_testata_title")}
      />

      <div className="pt pb">
        <div className="container">
          <div className="row">
            <div className="width50">
              <h2>{Traduci(lingua, "lavorazioni_come")}</h2>
              <p className="lead">{Traduci(lingua, "lavorazioni_lead")}</p>
              {Traduci(lingua, "lavorazioni_intro")}
            </div>
          </div>
        </div>
      </div>

      <div className="odd_even">
        <FluidContent
          align="left"
          titolo={Traduci(lingua, "lavorazioni_seamless_title")}
          testo={Traduci(lingua, "lavorazioni_seamless_text")}
        >
          <YoutubePlayer videoUrl="o4rFBJpP_fE" />
        </FluidContent>

        <FluidContent
          align="center"
          titolo={Traduci(lingua, "lavorazioni_tintura_title")}
          testo={Traduci(lingua, "lavorazioni_tintura_text")}
        >
          <YoutubePlayer videoUrl="tntV7wtOCuQ" />
        </FluidContent>

        <FluidContent
          align="center"
          titolo={Traduci(lingua, "lavorazioni_piuma_title")}
          testo={Traduci(lingua, "lavorazioni_piuma_text")}
        >
          <YoutubePlayer videoUrl="sfjqjy4jA44" />
        </FluidContent>

        <FluidContent
          align="center"
          titolo={Traduci(lingua, "lavorazioni_termosaldatura_title")}
          testo={Traduci(lingua, "lavorazioni_termosaldatura_text")}
        >
          <YoutubePlayer videoUrl="6GDd5Y23CiI" />
        </FluidContent>

        <FluidContent
          align="center"
          titolo={Traduci(lingua, "lavorazioni_stiro_title")}
          testo={Traduci(lingua, "lavorazioni_stiro_text")}
        >
          <YoutubePlayer videoUrl="3E83aRec9SA" />
        </FluidContent>

        <FluidContent
          align="center"
          titolo={Traduci(lingua, "lavorazioni_ultrasuoni_title")}
          testo={Traduci(lingua, "lavorazioni_ultrasuoni_text")}
        >
          <YoutubePlayer videoUrl="1s3MJSS1Ghg" />
        </FluidContent>

        <FluidContent
          align="center"
          titolo={Traduci(lingua, "lavorazioni_cuciture_title")}
          testo={Traduci(lingua, "lavorazioni_cuciture_text")}
        >
          <YoutubePlayer videoUrl="qofaLPa9ITM" />
        </FluidContent>

        <FluidContent
          align="center"
          titolo={Traduci(lingua, "lavorazioni_template_title")}
          testo={Traduci(lingua, "lavorazioni_template_text")}
        >
          <YoutubePlayer videoUrl="C0MVQ0MOp6E" />
        </FluidContent>

        <FluidContent
          align="right"
          titolo={Traduci(lingua, "lavorazioni_laser_title")}
          testo={Traduci(lingua, "lavorazioni_laser_text")}
        >
          <YoutubePlayer videoUrl="L6wGy_BhyUM" />
        </FluidContent>

        <FluidContent
          align="center"
          titolo={Traduci(lingua, "lavorazioni_taglio_automatico_title")}
          testo={Traduci(lingua, "lavorazioni_taglio_automatico_text")}
        >
          <YoutubePlayer videoUrl="QwLEZ1jYJqY" />
        </FluidContent>
      </div>
    </>
  );
};

export default Lavorazioni;
