import React from "react";

const ModuloContattaci = () => {
  return (
    <form
      action="/contatti/#wpcf7-f5-o1"
      method="post"
      className="wpcf7 wpcf7-form init"
    > 
      <input
        type="text"
        name="your-name"
        size="40"
        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
        aria-required="true"
        aria-invalid="false"
        placeholder="Il tuo nome"
      />
      <input
        type="email"
        name="your-email"
        size="40"
        placeholder="La tua email"
      />
      <input
        type="text"
        name="your-subject"
        size="40"
        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
        aria-required="true"
        aria-invalid="false"
        placeholder="Indirizzo"
      />
      <textarea
        name="your-message"
        cols="40"
        rows="10"
        placeholder="Il tuo messaggio"
      />
      <div className="accept">
        <p>
          Accettazione della legge sulla privacy ai sensi del Regolamento
          Europeo 679/2016
        </p>
        <label>
          <input
            type="checkbox"
            name="acceptance-313"
          />
          <span className="wpcf7-list-item-label">
            Ho letto l'informativa sulla Privacy ed accetto le condizioni.
          </span>
        </label>
      </div>
      <p>
        <button type="submit" value="Invia" className="button-submit" disabled="">Invia</button>
      </p>
    </form>
  );
};

export default ModuloContattaci;
