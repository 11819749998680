export const trad_eng = {
  menu_italia: "ITALIAN HEADQUARTERS",
  menu_made_in_europe: "MADE IN EUROPE",
  manu_team: "TEAM",
  menu_materiali: "MATERIALS",
  menu_lavorazioni: "MANUFACTURING",
  menu_mercati: "MARKETS",
  menu_quality: "QUALITY",
  menu_storia: "HISTORY",
  menu_contatti: "CONTACTS",

  titolo_slideshow: "We dress your dreams.",
  sottotitolo_slideshow:
    "PRODUCTION OF SPORTSWEAR, FASHION AND TECHNICAL GARMENTS.",
  silusi_partner:
    "Silusi is your partner for the production of unique and high quality items of clothing.",
  silusi_partner_sottotiolo:
    "We ensure experience, technology and assistance in every manufacturing phase, from the planning to the realization.",
  stats_siti_produttivi: "MANUFACTURING SITES",
  stats_dipendenti: "EMPLOYEES",
  stats_capi_prodotti: (
    <>
      GARMENTS PRODUCED
      <br />
      PER YEAR
    </>
  ),
  home_parallax_title:
    "Cutting-edge technologies and renovated tissues in order to realize exclusive garments which respect the environment we live in.",
  button_link_lavorazioni: "MANUFACTURING",
  pre_our_team: "OUR TEAM",
  our_team_title:
    "We love challenges and we face every project with passion and professionalism, making available our experience to achieve the best solution.",
  button_read_more: "Learn more",
  i_nostri_plus: "OUR PLUS",
  plus_title_1: (
    <>
      Quality & <br />
      technology
    </>
  ),
  plus_title_2: (
    <>
      Seriousness & <br />
      reliability
    </>
  ),
  plus_title_3: (
    <>
      Know-how & <br />
      experience
    </>
  ),
  plus_text_1:
    "Constant innovation, cutting-edge technologies and latest generation production systems for the realization of new projects.",
  plus_text_2:
    "We take to heart every project following it in all of its phases with seriousness and reliability from the planning to the realization.",
  plus_text_3:
    "Our experience to guarantee a complete and detailed product management from the design to the realization.",
  banner_quality_title: "Certified quality",
  button_vedi_tutte: "SEE ALL",

  // AZIENDA

  azienda_testata_pre: "COMPANY",
  azienda_testata_title:
    "Production of sportswear, fashion and technical garments.",
  azienda_testata_intro: (
    <>
      <p>
        Our historical headquarters in Sovere (BG), a few steps from Iseo Lake,
        is the heart of all the projects, the quality culture and the
        manufacturing excellence of Silusi.
      </p>
      <p>
        It is deployed on two areas with a total surface of 4.800 mq. All the
        tasks in the fields of management, administration, design, research,
        materials development, manufacturing, prototyping, control and quality
        check and goods warehouse for finished products take place in Sovere
        (BG).
      </p>
    </>
  ),
  azienda_stats_collaboratori: "PARTNERS",
  azienda_stats_magazzino: (
    <>
      FINISHED PRODUCTS
      <br />
      WAREHOUSE
    </>
  ),
  azienda_stats_materie_prime: (
    <>
      RAW MATERIALS
      <br />
      WAREHOUSE
    </>
  ),
  azienda_stats_uffici: "OFFICES",
  azienda_stats_styling: "STYLING ANNUALLY DESIGNED",
  azienda_stats_prototipi: "PROTOTYPES ANNUALLY REALIZED",
  azienda_parallax_title:
    "Communication, seriousness, transparency are our strengths since we believe in the direct communication with our clients.",
  azienda_parallax_text:
    "Thanks to the close collaboration we manage to follow every step of the productive process, from the drawing to the realization of the project, offering a constant service of assistance.",
  azienda_accordion_materiali: "Warehouse materials",
  azienda_accordion_progettazione: "Planning",
  azienda_accordion_prototipia: "Prototyping",
  azienda_accordion_controllo_qualita: "Quality check",
  azienda_accordion_magazzino_capi_finiti: "Finished garments warehouse",
  azienda_accordion_amministrazione: "Administration and clients Service",

  // ROMANIA - MADE IN EUROPE

  romania_pre: "MADE IN EUROPE - ROMANIA",
  romania_testata_title:
    "Production of sportswear, fashion and technical garments.",

  romania_stats_intro:
    "Silusi group has 4 owned companies in Romania, 100% checked, that support the realization of all the projects through their production lines and laboratories:",

  romania_intro: (
    <>
      <p>
        Tg Trotus headquarters in Romania, 50 km from Bacau and 400 km from
        Bucarest, Lusi Union Silusi Srl, with more than 300 people, is the main
        production unit dislocated on a total area of 12.500 mq whose 4.250 mq
        of covered surface.
      </p>
      <p>
        The projects developed in the Italian headquarters are realized in the 6
        production lines in Romania in order to be checked later in the quality
        department of the headquarters in Sovere.
      </p>
      <p>
        In the Romanian headquarters there are offices, raw materials warehouses
        which are sent from Italy, production lines and shipping warehouses of
        finished products.
      </p>
    </>
  ),
  romania_stats_intro:
    "Lusi Union takes advantage of external collaborations based on the production necessities with Romanian companies for a total of 14 production lines, 150 partners, and 650.000 produced items:",

  romania_stats_linee: "PRODUCTION LINES",
  romania_stats_collaboratori: "PARTNERS",
  romania_stats_capi: "GARMENTS",
  romania_accordion_materiali: "Materials warehouse",
  romania_accordion_taglio: "Cut",
  romania_accordion_laser: "Laser",
  romania_accordion_linea_produttiva: "Production line",
  romania_accordion_piuma: "Feather",
  romania_accordion_termosaldatura: "Heat-sealing",
  romania_accordion_incollaggio: "Gluing-Presses",
  romania_accordion_template: "Template",
  romania_accordion_finissaggio: "Finishing",
  romania_accordion_controllo_qualita: "Quality check",
  romania_accordion_capi_finiti: "Finished garments warehouse",

  // TEAM

  team_pre: "TEAM",
  team_testata_title:
    "We dress your dreams, transforming your ideas in big clothing projects",

  team_slogan: (
    <>
      We take <br />
      to heart <br />
      every project
    </>
  ),

  team_intro:
    "Silusi offers assistance on the entire project, making available a consolidated team of designers, product and production technicians who will accompany you from the drawing phase to the finished product’s delivery, transforming an idea into something unique.",

  our_team: "OUR TEAM",

  team_text:
    "We love challenges and we face every project with passion and professionalism, making available all our experience to offer the best solution to our Customers.",
  team_title_2: (
    <>
      We give value
      <br />
      to people
    </>
  ),
  team_text_2: (
    <>
      <h3>
        Our people are our value added. Thanks to the fortune of skills and
        their passion they can realize extraordinary projects and face
        successfully new challenges.
      </h3>
      <h4>
        Knowledge and competence together with serious dedication are the basis
        of our work. They allow us to be recognized and to assist our clients in
        a unique way, giving value to their projects.
      </h4>
      <h4>
        Reliability, seriousness, innovation and professionalism are the key
        words in our team.
      </h4>
    </>
  ),

  // MATERIALI
  materiali_pre: "MATERIALS",
  materiali_testata_title: "Technical and innovative raw materials",
  materiali_intro:
    "In the fashion industry the use of technical and innovative raw materials is always evolving.",
  materiali_intro_2:
    "Our experience, gained with technical latest generation tissues, allows us to handle new materials’ use.",

  tessuti_tecnici_title: "Technical waterproof tissues",
  tessuti_tecnici_lead:
    "Waterproof tissues, such as GORE-TEX ®, are long-term, breathable and comfortable and they offer a complete protection from atmospheric agents.",
  tessuti_tecnici_text: (
    <>
      <p>
        Thanks to our experience we are specialized in the manufacturing of any
        kind of material suited to the realization of any rainproof and
        windproof garment.
      </p>
      <p>Silusi has been certified GORE-TEX ® since the 1980s.</p>
    </>
  ),

  tessuti_piuma_title: "Feather",
  tessuti_piuma_lead:
    "The completely natural feather ensure the maximum heat matched with an extraordinary lightness. It is great both for the production of fashion garments and for extreme sportswear.",
  tessuti_piuma_text:
    "Our specialization in this field consists not only in the manufacturing of warm down garments with ready-made feather bags, but also in the direct injected filling of feather in the bag and in the garment itself.",

  tessuti_legno_title: "Wood",
  tessuti_legno_lead:
    "New materials such as the wood tissue are in the name of sustainability, quality and resistance. We are able to use this raw material for the realization of garments without giving up quality through the use of our technologies.",

  tessuti_dyneema_title: "Dyneema",
  tessuti_dyneema_lead: "We have made the Dyneema waterproof.",
  tessuti_dyneema_text: (
    <>
      <p>
        Dyneema is an innovative material which is naturally afraid of water,
        this means that it can’t absorb water and it is light even in the
        wettest conditions, it is UV rays resistant and chemically inert.
      </p>
      <p>
        We can work with any kind of Dyneema material, creating high performance
        and tough garments, ensuring their water resistance.
      </p>
    </>
  ),

  tessuti_luminescenza_title: "Luminescence",
  tessuti_luminescenza_lead:
    "The luminescent material loaded under a bulb or worn under the sun lights up immediately when it is brought in a dark place, indeed it behaves like kryptonite.",
  tessuti_luminescenza_text:
    "The innovative luminescent material together with our skills and technologies allows the realization of unique and high quality garments.",

  tessuti_fluorescenza_title: "Fluorescence",
  tessuti_fluorescenza_lead:
    "This material is designed with a fluorescent mixture and it reflects the light when it is illuminated.",
  tessuti_fluorescenza_text:
    "We are able to supervise this material which is very fragile in the process of manufacturing and it needs particular care and attention.",

  tessuti_metallo_title: "Metal",
  tessuti_metallo_lead:
    "With more than 11 kilometers of copper in each shirt, Full Metal Jacket is designed to help us being pioneer of the future intelligent and disease-resistant clothing.",
  tessuti_metallo_text:
    "The jacket is 65% copper made, it is soft, malleable, highly waterproof, windproof and breathable and it is supplied with a fleece which has lined neck and pockets, making it a perfect everyday technical garment.",

  tessuti_nylon_title: "Ballistic nylon",
  tessuti_nylon_lead:
    "We may not walk on the Red Planet for another decade or so. But it doesn’t mean that you can’t look like a high-tech visitor ready for Mars.",
  tessuti_nylon_text: (
    <>
      <p>
        Ballistic nylon and the external 3D printed also have a space that
        contains a vomit-bag for the space madness!
      </p>
      <p>
        Despite the ballistic nylon was originally created and used for
        bulletproof jackets, its duration and cut resistance have made it useful
        for non-combat applications.
      </p>
    </>
  ),

  tessuti_lane_title: "Technical wools",
  tessuti_lane_lead:
    "Light tissues, highly wear-resistant, with an extremely superior lengthening and comfort skill.",
  tessuti_lane_text:
    "The spun material isn’t hairy and it ensures a feeling of comfortable well-being to the touch. The fineness and softness of the technical wool together with the ease in the cleansing and maintenance, allow to realize snug items, perfect for sports use with all the benefits that wool owns by its nature.",

  // LAVORAZIONI
  lavorazioni_pre: "MANUFACTURING",
  lavorazioni_testata_title:
    "We dress your dreams, transforming your ideas into clothing",

  lavorazioni_come: "How?",
  lavorazioni_lead:
    "We supply our clients a constant assistance: from an accurate analysis of the drawing, to the development of the models and prototypes with the realization of samples and we support them in the management of the product supplying personalized services based on the needs.",
  lavorazioni_intro: (
    <>
      <p>
        The integration of the entire production process allows us to control
        every phase ensuring great results in terms of quality and efficiency.
      </p>
      <p>
        We take your idea, we develop it and we study the best use of the
        materials to create a prototype that starts being produced once
        approved. Every product is sent and delivered after an accurate quality
        check.
      </p>
      <p>
        Our team experience joined with the production plants technologies is
        the key of our success to handle a jointed and complex development
        cycle, which must be able to transform a hypothetical idea in a concrete
        garment as soon as possible.
      </p>
    </>
  ),

  lavorazioni_seamless_title: "Seamless",
  lavorazioni_seamless_text:
    "The seamless garment, which are products without seams, are the expression of our experience and innovative capacity. This working technique is used in dedicated lines for a new generation of garments that guarantee a linear and minimal design.",
  lavorazioni_tintura_title: "Dyeing",
  lavorazioni_tintura_text:
    "The garment is immersed in specific tanks or in dyeing machinery, filled with a mixture of pigments carefully studied in order to ensure a result with original tissues colorings.",
  lavorazioni_piuma_title: "Natural and synthetic feather",
  lavorazioni_piuma_text:
    "We are able to inject both natural and synthetic feather in any kind of garment, both with feather bag and with direct down filling. We carry out every manufacturing with latest generation manual or automatic machine for padding with direct injection into the garment through compressed air or pushing it through piston. The piston insertion allows the manufacturing of small canals.",
  lavorazioni_termosaldatura_title: "Heat-sealing",
  lavorazioni_termosaldatura_text:
    "Heat-sealing is a technique aimed at realizing waterproof garments in the field of technical professional and sports clothing. Heat-sealed products are completely waterproof. Thanks to our experience we are specialized in the heat-sealing of any kind of technical tissue and we are certified GORE-TEX ® since the 1980s.",
  lavorazioni_stiro_title: "Ironing and Press",
  lavorazioni_stiro_text:
    "Through the use of the ironing steam press we carry out the pre-ironing phase, so that the different sizes of the tissues can be stabilized with a view to the production phase. Thanks to the use of these techniques, joining the laser and the gluing phase we realize unique garments without seam.",
  lavorazioni_ultrasuoni_title: "Ultrasounds",
  lavorazioni_ultrasuoni_text:
    "We realize ultra-light products with a basic and linear aesthetics through the use of latest generation machines. Ultrasounds replace the traditional stitching technique cutting and joining fabrics without leaving seam allowances.",

  lavorazioni_cuciture_title: "Seams",
  lavorazioni_cuciture_text:
    "Sewing machines have always been essential in all processes and they are irreplaceable in the process of manufacturing. We realize garments using any kind of sewing machine in order to obtain finishing of all kinds.",

  lavorazioni_template_title: "Template",
  lavorazioni_template_text:
    "The automatic template machines allow us to realize higher quality free-form seams and particular, precise manufacturing",

  lavorazioni_laser_title: "Laser",
  lavorazioni_laser_text:
    "The laser cut allows to take full advantage of the creativity and the design. This technology cuts extremely quickly and allows to obtain unique pieces even in a series production. Moreover, the laser manufacturing of synthetic tissues allows raw cut edge and to seal the edges during the cut, obtaining clean edges without slipping out. The laser cut, combined with applications and productions which are not sewn but glued, increases considerably the products level, giving birth to a new generation of technical garments of highest quality.",

  lavorazioni_taglio_automatico_title: "Automatic cut",
  lavorazioni_taglio_automatico_text:
    "Thanks to the automatic next generation cuts we ensure efficiency and quality for any kind of production, small batches and series productions.",

  // MERCATI
  mercati_pre: "MARKETS",
  mercati_testata_title: "We produce and dress sectors",

  mercati_fashion_title: "Fashion",
  mercati_fashion_text: (
    <>
      <p>
        We assist successful and emerging designers and stylists in order to
        develop their ideas and transforming them into garments.
      </p>
      <p>
        We supply a complete service, starting from the realization of drawings
        to the production and delivery of garments, we are able to help our
        clients in any moment of their project, always ensuring high qualitative
        standards.
      </p>
    </>
  ),
  mercati_outdoor_title: "Outdoor",
  mercati_outdoor_text: (
    <>
      <p>
        We really care about the production of outdoor garments which are
        technical garments that aim at giving comfort, lightness, resistance and
        protection that fit in any weather condition.
      </p>
      <p>
        In order to obtain high-performance and innovative garments we use all
        the new technologies of production: laser cut, heat-sealing, ultrasounds
        and gluing.
      </p>
      <p>We have been certified GORE-TEX since 1980s</p>
    </>
  ),
  mercati_sport_title: "Sports items",
  mercati_sport_text: (
    <>
      <p>Every sport needs the right equipment, starting from the clothing.</p>
      <p>
        We can praise a multi-year knowledge in the sports clothing field,
        starting from motorcycling and bike leading to hunt, fishing, sailing,
        skiing and snowboard with quality manufacturing and precision to ensure
        great performances.
      </p>
    </>
  ),
  mercati_tempo_libero_title: "Free time",
  mercati_tempo_libero_text: (
    <>
      <p>Comfort, quality and technology for everyday clothing too.</p>
      <p>
        Our production, specialized both in the men and in the women’s line, is
        characterized by the use of special materials and cutting-edge
        technologies. Innovative garments that digress between sport and
        fashion, comfort and innovation, mixture of technical and trendy
        materials.
      </p>
      <p>
        Quilted jackets, casual and classic heavy jackets, jackets, citywear
        represent some examples of our experience in the field of jackets, which
        can be distinguished for a great quality care in order to have constant
        innovation.
      </p>
    </>
  ),
  mercati_workwear_title: "Workwear",
  mercati_workwear_text: (
    <>
      <p>Occupational safety is essential.</p>
      <p>
        We have always made all our expertise available for the security and the
        protection in the field of the work clothing.
      </p>
      <p>
        We produce high visibility garments, rainproof and windproof items of
        clothing from the easiest product to the greatest quality one, always
        respecting the European regulations.
      </p>
    </>
  ),

  // QUALITY
  quality_pre: "QUALITY",
  quality_testata_title:
    "Experience, technology and product quality are our success factor.",

  quality_intro_title:
    "The quality of the garments we produce mirrors our team’s quality, the manufacturing process and the materials we used.",
  quality_intro_text:
    "Attention and care are essential in every phase of management in order to realize garments of a higher quality.",

  quality_download_txt: "Download here our certificates",
  quality_calendar_dl: (
    <>
      <p>Scarica il nostro</p>
      <h3>calendario</h3>
      <h2>2023</h2>
    </>
  ),

  // STORIA
  storia_pre: "OUR HISTORY",
  storia_testata_title:
    "A family business ready for a new experience in the textile world was set up in 1976 starting from the vision of the founder Angelo Sigorini.",

  storia_text_1:
    "At the end of the 1970s the daughter Raffaella with her fiancé (and current husband) Pier Luigi Lumina, her brother Giorgio and her father Angelo Sigorini started a new experience in the textile world, transforming the familiar business into a structured and focused reality based on the realization of the best quilted jackets and fashion technical garments. Successively, the sister Clara and the brother-in-law Matteo started working with Pier Luigi, Raffaella and Giorgio.",
  storia_text_2: (
    <>
      Silusi Srl was officially
      <br />
      founded in 1985
    </>
  ),
  storia_text_3:
    "From a family-run, locally active, artisan company it has turned into a structured company, spreading its activity internationally thanks to a focused work management and the experience they acquired.",
  storia_text_4:
    "The company started working with technical workwear, then it specialized in any manufacturing typology thanks to the experiences and the skills they acquired.",
  storia_text_5:
    "Already in the late 1980s it was certified for the production of GORE-TEX garments, then it introduced cutting-edge techniques and technologies in order to realize any kind of project with innovative materials. Silusi srl is certified UNI EN ISO 9001:2000, testifying the management quality system.",
  storia_text_6: (
    <>
      <p>
        The European experience and a part of the production have been entrusted
        in Romania since 1991. In 1996 the Tg Trotus productive headquarters was
        purchased and the LUSI UNION SILUSI SRL was founded in order to expand
        the productive capacities organizing the whole process from the design
        to the production.
      </p>
      <p>
        Three new productive lines have been added since 2001 with the creation
        of SILMADUM FASHION SRL, in 2015 the LUBOSIL SRL was founded and in 2022
        CELITEX. All the Romanian websites have been certified UNI EN ISO
        9001:2000 since 1998.
      </p>
    </>
  ),
  storia_text_7:
    "The entire productive process’ assimilation together with experience, quality and punctuality allow to guarantee a complete and detailed product management. Nowadays, we are reliable partners of emerging stylists and of the best international fashion and sport brands, since we are able to ensure the customer a finished product of the highest quality.",

  // CONTATTI
  contatti_pre: "CONTACTS",
  contatti_testata_title:
    "Your partner for the production of unique garments",

  contatti_office_title:
    "Contact us directly by email to the office you are interested in",

  contatti_column_1: "ITALY SALES OFFICE",
  contatti_column_2: "FOREIGN SALES OFFICE",
  contatti_column_3: "PURCHASING OFFICE",
  contatti_column_4: "ITALIAN PRODUCT OFFICE",
  contatti_column_5: "ENG PRODUCT OFFICE",
  contatti_column_6: "LOGISTICS DEPARTMENT",
  contatti_column_7: "ADMINISTRATION OFFICE",
  contatti_column_8: "RESEARCH AND DEVELOPMENT OFFICE",
  contatti_column_9: "TECHNICAL OFFICE",

  seo_home_title: "Silusi S.r.l. | Home | We dress your dreams.",
  seo_home_description: "Silusi is your partner for the production of unique and high quality items of clothing.",

  seo_azienda_title: "Silusi S.r.l. | Italian headquarter",
  seo_azienda_description: "We ensure experience, technology and assistance in every manufacturing phase, from the planning to the realization.",

  seo_sedi_produttive_title: "Silusi S.r.l. | Made in Europe | Romania",
  seo_sedi_produttive_description: "Cutting-edge technologies and renovated tissues in order to realize exclusive garments which respect the environment we live in.",

  seo_il_nostro_team_title: "Silusi S.r.l. | Team",
  seo_il_nostro_team_description: "We dress your dreams, transforming your ideas in big clothing projects.",

  seo_materiali_title: "Silusi S.r.l. | Materials",
  seo_materiali_description: "In the fashion industry the use of technical and innovative raw materials is always evolving.",

  seo_lavorazioni_title: "Silusi S.r.l. | Manufacturing",
  seo_lavorazioni_description: "We dress your dreams, transforming your ideas into clothing.",

  seo_settori_title: "Silusi S.r.l. | Markets",
  seo_settori_description: "We produce and dress sectors: Fashion, Outdoor, Sports items, Free time, Workwear",

  seo_qualita_title: "Silusi S.r.l. | Quality",
  seo_qualita_description: "Experience, technology and product quality are our success factor.",

  seo_storia_title: "Silusi S.r.l. | History",
  seo_storia_description: "From a family-run, locally active, artisan company it has turned into a structured company, spreading its activity internationally thanks to a focused work management and the experience they acquired.",

  seo_contatti_title: "Silusi S.r.l. | Contacts",
  seo_contatti_description: "Your partner for the production of unique garments",

};
