import React from "react";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollTo from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollTo);

const sliderData = [
  "https://images.unsplash.com/photo-1611660246350-e206e04bd966?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80",
  "https://images.unsplash.com/photo-1611774017274-ec655f187ef3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1340&q=80",
  "https://images.unsplash.com/photo-1611702700332-9af2ba35887c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80",
  "https://images.unsplash.com/photo-1611764650125-5e4be2463de5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80",
  "https://images.unsplash.com/photo-1611757790630-79892f4bce93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
];

const Gallery6Scroll = () => {
  const text = useRef({ value: 1 });

  useEffect(() => {
    gsap
      .timeline({
        defaults: {
          scrollTrigger: {
            trigger: "#gallery_weird",
            scrub: 0.5,
            pin: true,
            onUpdate: (self) => {
              const clamp = gsap.utils.clamp(-2, 2);
              let skew = clamp(self.getVelocity() / -300);
              gsap.to("#gallery_weird .sliderImg", { skewX: -skew });
            },
          },
        },
      })
      .to("#gallery_weird .slider", {
        x: -(sliderData.length - 3) * (window.innerWidth / 3),
      })
      .to("#gallery_weird .slider .sliderImg", {
        scale: 1.2,
      });
  }, [text]);

  return (
    <div id="gallery_weird">
      <div className="slider">
        {sliderData.map((item, index) => (
          <img className="sliderImg" src={item} alt={"img"} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Gallery6Scroll;
