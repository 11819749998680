export const traduzioni = {
  menu_italia: "SEDE ITALIA",
  menu_made_in_europe: "MADE IN EUROPE",
  manu_team: "TEAM",
  menu_materiali: "MATERIALI",
  menu_lavorazioni: "LAVORAZIONI",
  menu_mercati: "MERCATI",
  menu_quality: "QUALITÀ",
  menu_storia: "STORIA",
  menu_contatti: "CONTATTI",

  titolo_slideshow: "Vestiamo i tuoi sogni",
  sottotitolo_slideshow: "PRODUZIONE ABBIGLIAMENTO FASHION, TECNICO E SPORTIVO",
  silusi_partner:
    "Silusi è il tuo partner per la produzione di capi di abbigliamento unici e di alta qualità.",
  silusi_partner_sottotiolo:
    "Dalla progettazione alla realizzazione ti garantiamo in ogni fase di lavorazione esperienza, tecnologia e assistenza.",
  stats_siti_produttivi: "SITI PRODUTTIVI",
  stats_dipendenti: (
    <>
      DIPENDENTI E
      <br />
      COLLABORATORI
    </>
  ),
  stats_capi_prodotti: (
    <>
      CAPI PRODOTTI
      <br />
      ALL’ANNO
    </>
  ),
  home_parallax_title:
    "Tecnologie all’avanguardia e tessuti innovati per realizzare capi esclusivi nel rispetto dell’ambiente in cui viviamo.",
  button_link_lavorazioni: "LAVORAZIONI",
  pre_our_team: "OUR TEAM",
  our_team_title:
    "Amiamo le sfide e affrontiamo ogni progetto con passione e professionalità, mettendo a disposizione tutta la nostra esperienza per realizzare la soluzione migliore.",
  button_read_more: "SCOPRI DI PIù",
  i_nostri_plus: "I NOSTRI PLUS",
  plus_title_1: (
    <>
      Qualità &<br />
      tecnologia
    </>
  ),
  plus_title_2: (
    <>
      Serietà &<br />
      affidabilità
    </>
  ),
  plus_title_3: (
    <>
      Know-how &<br />
      Esperienza
    </>
  ),
  plus_text_1:
    "Innovazione continua, tecnologie all’avanguardia e sistemi di produzione di ultima generazione per realizzare nuovi progetti.",
  plus_text_2:
    "Prendiamo a cuore ogni progetto seguendolo in ogni sua fase con serietà ed affidabilità dalla progettazione alla realizzazione.",
  plus_text_3:
    "La nostra esperienza per garantire una gestione completa del prodotto curata nei minimi dettagli dall’ideazione alla realizzazione.",
  banner_quality_title: "Qualità certificata",
  button_vedi_tutte: "VEDI TUTTE",

  // AZIENDA

  azienda_testata_pre: "AZIENDA",
  azienda_testata_title: "Produzione abbigliamento fashion, tecnico e sportivo",
  azienda_testata_intro: (
    <>
      <p>
        La nostra storica sede di Sovere (BG), a pochi passi dal lago d’Iseo, è
        il cuore di tutti i progetti e della cultura della qualità e
        dell’eccellenza produttiva di Silusi.
      </p>
      <p>
        Dislocata su due aree con una superficie totale di 4.800 mq, a Sovere
        (BG) si svolgono tutte le attività gestionali, amministrative, di
        design, ricerca e sviluppo materiali, progettazione, prototipazione,
        controllo qualità e magazzino merci per i prodotti finiti.
      </p>
    </>
  ),
  azienda_stats_collaboratori: "COLLABORATORI",
  azienda_stats_magazzino: (
    <>
      MAGAZZINO
      <br />
      PRODOTTI FINITI
    </>
  ),
  azienda_stats_materie_prime: (
    <>
      MAGAZZINO
      <br />
      MATERIE PRIME
    </>
  ),
  azienda_stats_uffici: "UFFICI",
  azienda_stats_styling: "STYLING PROGETTATI ANNUALMENTE",
  azienda_stats_prototipi: "PROTOTIPI REALIZZATI ANNUALMENTE",
  azienda_parallax_title:
    "Comunicazione, serietà e trasparenza sono i nostri punti forza perché crediamo nel diretto confronto con i nostri clienti.",
  azienda_parallax_text:
    "Grazie alla stretta collaborazione che si genera riusciamo a seguire ogni passo del processo produttivo dal disegno alla realizzazione del progetto, offrendo un servizio di assistenza continua.",
  azienda_accordion_materiali: "Magazzino materiali",
  azienda_accordion_progettazione: "Progettazione",
  azienda_accordion_prototipia: "Prototipia",
  azienda_accordion_controllo_qualita: "Controllo qualità",
  azienda_accordion_magazzino_capi_finiti: "Magazzino capi finiti",
  azienda_accordion_amministrazione: "Amministrazione e Servizio clienti",

  // ROMANIA - MADE IN EUROPE

  romania_pre: "MADE IN EUROPE - ROMANIA",
  romania_testata_title: "Produzione abbigliamento fashion, tecnico e sportivo",

  romania_stats_intro:
    "Il Gruppo Silusi ha 4 aziende di proprietà in Romania, controllate al 100%, che supportano con le loro linee produttive e laboratori la realizzazione di tutti i progetti:",

  romania_intro: (
    <>
      <p>
        La sede di Tg Trotus in Romania, a 50 km da Bacau e 400 km da Bucarest,
        Lusi Union Silusi Srl, con più di 300 persone, è la principale unità
        produttiva dislocata su un’area totale di 12.500 mq di cui 4.250 mq di
        superficie coperta.
      </p>
      <p>
        I progetti sviluppati nella sede italiana vengono realizzati nelle 6
        linee di produzione in Romania per poi essere controllati nel reparto
        qualità della sede di Sovere (BG).
      </p>
      <p>
        Nella sede rumena sono presenti uffici, magazzini delle materie prime
        che vengono inviati dall’Italia, linee di produzione e magazzini di
        spedizione dei prodotti finiti.
      </p>
    </>
  ),
  romania_stats_intro:
    "Lusi Union si avvale di collaborazioni esterne in base alle esigenze di produzione con aziende rumene per un totale di 14 linee produttive, 150 collaboratori 650.000 capi prodotti:",

  romania_stats_linee: "linee produttive",
  romania_stats_collaboratori: "collaboratori",
  romania_stats_capi: "capi",
  romania_accordion_materiali: "Magazzino materiali",
  romania_accordion_taglio: "Taglio",
  romania_accordion_laser: "Laser",
  romania_accordion_linea_produttiva: "Linea produttiva",
  romania_accordion_piuma: "Piuma",
  romania_accordion_termosaldatura: "Termosaldatura",
  romania_accordion_incollaggio: "Incollaggio, Presse",
  romania_accordion_template: "Template",
  romania_accordion_finissaggio: "Finissaggio",
  romania_accordion_controllo_qualita: "Controllo qualità",
  romania_accordion_capi_finiti: "Magazzino capi finiti",

  // TEAM

  team_pre: "TEAM",
  team_testata_title:
    "Vestiamo i tuoi sogni, trasformando le tue idee in grandi progetti di abbigliamento",

  team_slogan: (
    <>
      Prendiamo a<br />
      cuore <br />
      ogni progetto
    </>
  ),

  team_intro:
    "Silusi ti offre un’assistenza su tutto il progetto, mettendo a disposizione un team consolidato di modellisti, tecnici di prodotto e di produzione che ti accompagneranno dalla fase del disegno fino alla consegna del prodotto finito trasformando così un’idea in qualcosa di unico.",

  our_team: "OUR TEAM",

  team_text:
    "Amiamo le sfide e affrontiamo ogni progetto con passione e professionalità, mettendo a disposizione tutta la nostra esperienza per offrire la soluzione migliore ai nostri clienti.",
  team_title_2: (
    <>
      Diamo valore
      <br />
      alle persone
    </>
  ),
  team_text_2: (
    <>
      <h3>
        Il nostro valore aggiunto sono le nostre persone. Grazie al patrimonio
        di competenze e alla loro passione possono realizzare progetti
        straordinari e affrontare con successo nuove sfide.
      </h3>
      <h4>
        Conoscenza e preparazione unite a una grande determinazione sono alla
        base del nostro lavoro che ci permettono di distinguerci e assistere i
        nostri clienti in modo unico dando valore ai loro progetti.
      </h4>
      <h4>
        Affidabilità, serietà, innovazione e professionalità sono le parole
        chiave del nostro team.
      </h4>
    </>
  ),

  // MATERIALI
  materiali_pre: "MATERIALI",
  materiali_testata_title: "Materie prime tecniche e innovative",
  materiali_intro:
    "Nel settore della moda l’utilizzo di materie prime tecniche e innovative è sempre in evoluzione.",
  materiali_intro_2:
    "La nostra esperienza, maturata con tessuti tecnici di ultima generazione, ci permette di poter gestire l’utilizzo di nuovi materiali.",

  tessuti_tecnici_title: "Tessuti tecnici antiacqua",
  tessuti_tecnici_lead:
    "I tessuti antiacqua, come il GORE-TEX ®, offrono una protezione completa dagli agenti atmosferici, sono di lunga durata, traspiranti e confortevoli.",
  tessuti_tecnici_text: (
    <>
      <p>
        Grazie alla nostra esperienza siamo specializzati nella lavorazione
        qualsiasi tipo di materiale imparabile adattato alla realizzazione di
        qualsiasi indumento antipioggia e antivento.
      </p>
      <p>Silusi è certificata GORE-TEX ® dagli anni ’80.</p>
    </>
  ),

  tessuti_piuma_title: "Piuma",
  tessuti_piuma_lead:
    "La piuma, completamente naturale, garantisce il massimo calore abbinato ad una straordinaria leggerezza ed è ottima sia per la produzione di capi fashion che abbigliamento sportivo estremo.",
  tessuti_piuma_text:
    "La nostra specializzazione in questo ambito consiste non solo nella lavorazione di capi imbottiti con sacchi piuma già pronti, ma nel riempimento ad iniezione diretta di piuma nel sacco e nel capo stesso.",

  tessuti_legno_title: "Legno",
  tessuti_legno_lead:
    "Nuovi materiali come il tessuto di legno sono all’insegna della sostenibilità, della qualità e della resistenza. Con l’utilizzo delle nostre tecnologie siamo in grado di utilizzare questa materia prima per la realizzazione di indumenti senza rinunciare alla qualità.",

  tessuti_dyneema_title: "Dyneema",
  tessuti_dyneema_lead: "Abbiamo reso il Dyneema impermeabile",
  tessuti_dyneema_text: (
    <>
      <p>
        Il Dyneema è un materiale innovativo naturalmente idrofobo, il che
        significa che non può assorbire l'acqua e rimane leggero anche nelle
        condizioni più umide, è resistente ai raggi UV e chimicamente inerte.
      </p>
      <p>
        Possiamo lavorare qualsiasi tipo di materiale Dyneema realizzando
        indumenti dalle alte prestazioni, resistenti e garantendone
        l’impermeabilità.
      </p>
    </>
  ),

  tessuti_luminescenza_title: "Luminescenza",
  tessuti_luminescenza_lead:
    "Il materiale luminescente caricato sotto una lampadina o indossato al sole, non appena lo si porta in un luogo buio si illumina come la kryptonite.",
  tessuti_luminescenza_text:
    "L’innovativo materiale luminescente unito alle nostre competenze e tecnologie permette di realizzare capi unico e di qualità.",

  tessuti_fluorescenza_title: "Fluorescenza",
  tessuti_fluorescenza_lead:
    "Progettato con un composto fluorescente, questo materiale riflette la luce se illuminato.",
  tessuti_fluorescenza_text:
    "Siamo in grado di gestire questo materiale che risulta essere molto delicato nel processo di lavorazione e necessita particolare cura e attenzione.",

  tessuti_metallo_title: "Metallo",
  tessuti_metallo_lead:
    "Con oltre 11 chilometri di rame in ogni camicia, la Full Metal Jacket è progettato per aiutarci a essere pionieri del futuro abbigliamento intelligente e resistente alle malattie.",
  tessuti_metallo_text:
    "Costruita con il 65% di rame, la giacca è morbida, malleabile, altamente impermeabile, antivento e traspirante e viene fornito con un pile collo e tasche foderati, che lo rendono un perfetto capo tecnico per tutti i giorni.",

  tessuti_nylon_title: "Nylon",
  tessuti_nylon_lead:
    "Potremmo non camminare sul Pianeta Rosso per un altro decennio o giù di lì. Ma ciò non significa che non puoi sembrare un visitatore high-tech pronto per Marte.",
  tessuti_nylon_text: (
    <>
      <p>
        Il nylon balistico e l'esterno stampato in 3D presentano anche una
        caratteristica "apertura" che contiene una vomit-bag per il mal di
        spazio!
      </p>
      <p>
        Sebbene il nylon balistico sia stato originariamente creato e utilizzato
        nei giubbotti antiproiettile, la sua durata e resistenza al taglio lo
        hanno reso utile per applicazioni non da combattimento.
      </p>
    </>
  ),

  tessuti_lane_title: "Lane tecniche",
  tessuti_lane_lead:
    "Tessuti leggeri, altamente resistenti all’usura, con una capacità di allungamento e di comfort estremamente superiori.",
  tessuti_lane_text:
    "Il filato risulta privo di pelosità e al tatto garantisce una sensazione di confortevole benessere. La finezza e morbidezza della lana tecnica, unita alla facilità di lavaggio e manutenzione, permette di realizzare capi aderenti al corpo perfetti per l’utilizzo sportivo con tutti i benefici che la lana possiede per sua natura.",

  // LAVORAZIONI
  lavorazioni_pre: "LAVORAZIONI",
  lavorazioni_testata_title:
    "Vestiamo i tuoi sogni, trasformando le tue idee in abbigliamento",

  lavorazioni_come: "Come?",
  lavorazioni_lead:
    "Forniamo ai nostri clienti una continua assistenza: da un’accurata analisi del disegno, allo sviluppo dei modelli e dei prototipi con la realizzazione dei campionari e li supportiamo nella gestione del prodotto fornendo servizi personalizzati a seconda delle esigenze.",
  lavorazioni_intro: (
    <>
      <p>
        L’integrazione dell’intero processo di produzione ci permette di
        controllare ogni fase garantendo ottimi risultati in termini di qualità
        ed efficienza.
      </p>
      <p>
        Prendiamo la tua idea, la sviluppiamo, studiamo il miglior utilizzo dei
        materiali e creiamo un prototipo che una volta approvato viene messo in
        produzione. Ogni prodotto a seguito di un accurato controllo qualità,
        viene spedito e consegnato.
      </p>
      <p>
        L’esperienza del nostro team unita alle tecnologie degli impianti
        produttivi è la chiave del nostro successo per gestire un ciclo di
        sviluppo articolato e complesso, che deve riuscire a trasformare nel più
        breve tempo possibile un’idea in capo d’abbigliamento.
      </p>
    </>
  ),

  lavorazioni_seamless_title: "Seamless",
  lavorazioni_seamless_text:
    "I capi seamless, ovvero prodotti senza cuciture, sono l'espressione della nostra esperienza e della capacità innovativa. Questa tecnica di lavorazione viene utilizzata in linee dedicate per una nuova generazione di capi d’abbigliamento che garantiscono un design lineare e minimal.",
  lavorazioni_tintura_title: "Tintura",
  lavorazioni_tintura_text:
    "Il capo viene immerso in vasche apposite o in macchinari per la tintura, riempite con un mix di pigmenti attentamente studiati per garantire un risultato con tessuti dalle colorazioni singolari.",
  lavorazioni_piuma_title: "Piuma naturale e sintetica",
  lavorazioni_piuma_text:
    "Siamo in grado di iniettare sia piuma naturale che sintetica in qualsiasi tipo di capo, sia con sacco piuma che impiumaggio diretto. Eseguiamo ogni lavorazione con impiumatrici di ultima generazione sia manuali che automatiche con iniezione diretta nel capo ad aria compressa o inserimento a spinta tramite stantuffo. L’inserimento a stantuffo permette la lavorazione di piccoli canali.",
  lavorazioni_termosaldatura_title: "Termosaldatura",
  lavorazioni_termosaldatura_text:
    "La termosaldatura è una tecnica per realizzare capi waterproof per il settore dell’abbigliamento tecnico professionale e sportivo. I prodotti termosaldati sono completamente impermeabili all’acqua. Grazie alla nostra esperienza siamo specializzati nella termosaldatura di qualsiasi tipo di tessuto tecnico e siamo certificati GORE-TEX ® dagli anni ’80.",
  lavorazioni_stiro_title: "Stiro e Pressa",
  lavorazioni_stiro_text:
    "Con l’uso della pressa da stiro a vapore effettuiamo il pre-stiro, in modo che le dimensioni dei diversi tessuti possano essere stabilizzate in vista della fase di produzione. Grazie all’utilizzo di queste tecniche, unendo la laseratura e l’incollaggio, realizziamo capi unici e senza cuciture.",
  lavorazioni_ultrasuoni_title: "Ultrasuoni",
  lavorazioni_ultrasuoni_text:
    "Con l’utilizzo di macchine di ultima generazione a ultrasuoni realizziamo prodotti ultraleggeri con un’estetica essenziale e lineare. Gli ultrasuoni si sostituiscono alla tradizionale tecnica della cucitura tagliando e unendo i tessuti senza lasciare margini di cucitura.",

  lavorazioni_cuciture_title: "Cuciture",
  lavorazioni_cuciture_text:
    "Le macchine da cucire sono da sempre alla base di ogni lavorazione e insostituibili nel processo di produzione. Realizziamo capi d’abbigliamento utilizzando qualsiasi tipo di macchina da cucire per ottenere finiture di ogni genere.",

  lavorazioni_template_title: "Template",
  lavorazioni_template_text:
    "Le macchine automatiche template ci permettono di realizzare con maggior qualità le cuciture di trapuntatura e lavorazioni particolari di precisione.",

  lavorazioni_laser_title: "Laser",
  lavorazioni_laser_text:
    "Il taglio laser consente di sfruttare al massimo la creatività e il design. Questa tecnologia taglia in modo estremamente rapido e permette di ottenere pezzi unici anche in una produzione di serie. Inoltre, la lavorazione laser dei tessuti sintetici permette di tagliare a vivo e sigillare i bordi durante il taglio, ottenendo così bordi puliti, senza che questi sfilino. Il taglio laser, combinato ad applicazioni e lavorazioni non cucite ma incollate, aumenta notevolmente il livello dei prodotti, dando vita ad una nuova generazione di capi tecnici di altissima qualità.",

  lavorazioni_taglio_automatico_title: "Taglio automatico",
  lavorazioni_taglio_automatico_text:
    "Grazie ai tagli automatici di ultima generazione garantiamo efficienza e qualità per qualsiasi tipo di produzione, piccoli lotti e produzioni in serie.",

  // MERCATI
  mercati_pre: "MERCATI",
  mercati_testata_title: "Produciamo e vestiamo i settori",

  mercati_fashion_title: "Fashion",
  mercati_fashion_text: (
    <>
      <p>
        Assistiamo designer e stilisti affermati ed emergenti per sviluppare le
        loro idee e trasformarle in capi di abbagliamento.
      </p>
      <p>
        Fornendo un servizio completo, che va dalla realizzazione dei disegni,
        alla produzione e consegna dei capi, siamo in grado di affiancare i
        nostri clienti in qualsiasi momento del loro progetto garantendo sempre
        elevati standard qualitativi.
      </p>
    </>
  ),
  mercati_outdoor_title: "Outdoor",
  mercati_outdoor_text: (
    <>
      <p>
        Mettiamo tutta la cura nella produzione di capi outdoor che sono capi
        tecnici che puntano a dare confort, leggerezza, resistenza e protezione
        e si adattano ad ogni condizione climatica.
      </p>
      <p>
        Per ottenere capi dalle alte prestazioni e innovativi ci avvaliamo di
        tutte le ultime tecnologie di produzione: taglio laser, termosaldature,
        ultrasuoni e incollaggi.
      </p>
      <p>Siamo certificati GORE-TEX dagli anni ’80.</p>
    </>
  ),
  mercati_sport_title: "Articoli sportivi",
  mercati_sport_text: (
    <>
      <p>
        Per ogni sport è necessaria la giusta attrezzatura, a partire
        dall’abbigliamento.
      </p>
      <p>
        Possiamo vantare una pluriennale conoscenza del settore
        dell’abbigliamento sportivo, partendo da motociclismo a bike per
        arrivare a caccia, pesca, vela, sci e snowboard con lavorazioni di
        qualità e precisione per garantire grandi performance.
      </p>
    </>
  ),
  mercati_tempo_libero_title: "Tempo libero",
  mercati_tempo_libero_text: (
    <>
      <p>
        Confort, qualità e tecnologia anche per l’abbigliamento di tutti i
        giorni.
      </p>
      <p>
        La nostra produzione, specializzata sia nella linea uomo che donna, è
        caratterizzata dall’utilizzo di materiali speciali e tecnologie
        all’avanguardia. Capi innovativi che sconfinano tra sport e fashion,
        comodità e innovazione, mescolanza di materiali tecnici e di tendenza.
      </p>
      <p>
        Piumini imbottiti, giacconi uomo casual e classic, giubbotti, citywear
        sono alcuni esempi della nostra esperienza nel settore della
        giubbotteria, che si contraddistingue per una grande cura della qualità
        e per una costante innovazione.
      </p>
    </>
  ),
  mercati_workwear_title: "Workwear",
  mercati_workwear_text: (
    <>
      <p>La sicurezza sul lavoro è indispensabile.</p>
      <p>
        Da sempre mettiamo tutta la nostra competenza a servizio della sicurezza
        e protezione nel settore dell’abbigliamento da lavoro.
      </p>
      <p>
        Dal prodotto più semplice al capo di grande qualità, produciamo capi
        alta visibilità, capi antipioggia e antivento, nel rispetto di tutte le
        normative europee.
      </p>
    </>
  ),

  // QUALITY
  quality_pre: "QUALITY",
  quality_testata_title:
    "Esperienza, tecnologia e qualità del prodotto sono il nostro fattore di successo",

  quality_intro_title:
    "La qualità dei capi di abbigliamento che produciamo è il riflesso della qualità del nostro team, del processo produttivo e dei materiali utilizzati.",
  quality_intro_text:
    "L’attenzione e la cura vengono messe in ogni fase di gestione dei progetti per poter realizzare capi di abbigliamento di una qualità superiore.",

  quality_download_txt: "Scarica qui i nostri certificati",
  quality_calendar_dl: (
    <>
      <p>Scarica il nostro</p>
      <h3>calendario</h3>
      <h2>2023</h2>
    </>
  ),

  // STORIA
  storia_pre: "STORIA",
  storia_testata_title:
    "Nel 1976 dalla visione del fondatore Angelo Sigorini si avvia un’azienda familiare pronta per una nuova esperienza nel mondo tessile.",

  storia_text_1:
    "La figlia Raffaella insieme al fidanzato Pier Luigi Lumina (diventato poi marito) e al fratello Giorgio, con il papà Angelo Sigorini, alla fine degli anni 70, hanno intrapreso una nuova esperienza all’insegna del mondo tessile, trasformando l’azienda familiare in una realtà strutturata focalizzata sulla realizzazione dei migliori piumini e capi tecnici della moda. Successivamente si uniscono alla guida, con Pier Luigi, Raffaella e Giorgio, anche la sorella Clara ed il cognato Matteo.",
  storia_text_2: (
    <>
      Nel 1985 nasce
      <br />
      ufficialmente la Silusi Srl
    </>
  ),
  storia_text_3:
    "Da azienda artigiana a carattere familiare e attiva a livello locale si trasforma in pochi anni, grazie a una mirata gestione del lavoro e all’esperienza acquisita, in un’azienda strutturata espandendo la propria attività a livello internazionale.",
  storia_text_4:
    "L’azienda ha iniziato occupandosi della lavorazione di capi di abbigliamento da lavoro tecnici e con le competenze e le esperienze acquisite ha potuto specializzarsi in tutte le tipologie di lavorazione.",
  storia_text_5:
    "Già alla fine degli anni ‘80 è stata certificata per la produzione di capi in GORE-TEX, per poi introdurre tecniche e tecnologie sempre più all’avanguardia per realizzare qualsiasi tipo di progetto con materiali innovativi. A prova della qualità del sistema di gestione dal 2001 Silusi srl è certificata UNI EN ISO 9001:2000.",
  storia_text_6: (
    <>
      <p>
        Dal 1991 inizia l’esperienza europea e parte della produzione viene
        affidata in Romania. Nel 1996 viene acquisita la sede produttiva di Tg
        Trotus e fondata la LUSI UNION SILUSI SRL per poter ampliare la capacità
        produttiva potendo gestire tutto il processo dalla ideazione alla
        produzione.
      </p>
      <p>
        Dal 2001 vengono aggiunte tre nuove linee produttive con la creazione
        della SILMADUM FASHION SRL, nel 2015 viene fondata la LUBOSIL SRL e nel
        2022 CELITEX. Tutte i siti rumeni sono certificati UNI EN ISO 9001:2000
        dal 1998.
      </p>
    </>
  ),
  storia_text_7:
    "L’integrazione dell’intero processo produttivo unite ad esperienza, qualità e puntualità permettono di garantire una gestione completa del prodotto curata nei minimi dettagli. Oggi siamo partner affidabile per i migliori brand del fashion internazionale, dello sport e per gli stilisti emergenti perché siamo in grado di assicurare al Cliente la certezza di un prodotto finito della massima qualità.",

  // CONTATTI
  contatti_pre: "CONTATTI",
  contatti_testata_title:
    "Il tuo partner per la produzione di capi di abbigliamento unici",

  contatti_office_title:
    "Contattaci direttamente, attraverso email all'ufficio di tuo interesse:",

  contatti_column_1: "UFFICIO COMMERCIALE ITALIA",
  contatti_column_2: "UFFICIO COMMERCIALE ESTERO",
  contatti_column_3: "UFFICIO ACQUISTI",
  contatti_column_4: "UFFICIO PRODOTTO ITA",
  contatti_column_5: "UFFICIO PRODOTTO ENG",
  contatti_column_6: "UFFICIO LOGISTICA",
  contatti_column_7: "UFFICIO AMMINISTRAZIONE",
  contatti_column_8: "UFFICIO RICERCA & SVILUPPO",
  contatti_column_9: "UFFICIO TECNICO",

  seo_home_title: "Silusi S.r.l. | Home | Vestiamo i tuoi sogni",
  seo_home_description: "Silusi è il tuo partner per la produzione di capi di abbigliamento unici e di alta qualità.",

  seo_azienda_title: "Silusi S.r.l. | Azienda | Italia",
  seo_azienda_description: "Dalla progettazione alla realizzazione ti garantiamo in ogni fase di lavorazione esperienza, tecnologia e assistenza.",

  seo_sedi_produttive_title: "Silusi S.r.l. | Made in Europe | Romania",
  seo_sedi_produttive_description: "Tecnologie all’avanguardia e tessuti innovati per realizzare capi esclusivi nel rispetto dell’ambiente in cui viviamo.",

  seo_il_nostro_team_title: "Silusi S.r.l. | Team",
  seo_il_nostro_team_description: "Vestiamo i tuoi sogni, trasformando le tue idee in grandi progetti di abbigliamento",

  seo_materiali_title: "Silusi S.r.l. | Materie prime tecniche e innovative",
  seo_materiali_description: "Nel settore della moda l’utilizzo di materie prime tecniche e innovative è sempre in evoluzione.",

  seo_lavorazioni_title: "Silusi S.r.l. | Lavorazioni",
  seo_lavorazioni_description: "Prendiamo la tua idea, la sviluppiamo, studiamo il miglior utilizzo dei materiali e creiamo un prototipo che una volta approvato viene messo in produzione.",

  seo_settori_title: "Silusi S.r.l. | Mercati",
  seo_settori_description: "Produciamo e vestiamo i settori: Fashion, Outdoor, Articoli sportivi, Tempo libero, Workwear",

  seo_qualita_title: "Silusi S.r.l. | Qualità",
  seo_qualita_description: "Esperienza, tecnologia e qualità del prodotto sono il nostro fattore di successo",

  seo_storia_title: "Silusi S.r.l. | Storia",
  seo_storia_description: "Da azienda artigiana a carattere familiare e attiva a livello locale si trasforma in pochi anni, grazie a una mirata gestione del lavoro e all’esperienza acquisita, in un’azienda strutturata espandendo la propria attività a livello internazionale.",

  seo_contatti_title: "Silusi S.r.l. | Contatti",
  seo_contatti_description: "Il tuo partner per la produzione di capi di abbigliamento unici",
};
