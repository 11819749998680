import React from "react";
import BannerBW from "../componenti/BannerBW";
import Cta from "../componenti/Cta";
import Gallery from "../componenti/Gallery/Gallery";
import Gallery6Scroll from "../componenti/Gallery/Gallery6Scroll";
import GalleryH from "../componenti/Gallery/GalleryH";
import Testata from "../componenti/Testata";
import { ParallaxBanner } from "react-scroll-parallax";
import Plus from "../componenti/Plus";
import SectionOdometer from "../componenti/SectionOdometer";
import Accordion from "../componenti/Accordion/Accordion";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";
const lingua = localStorage.getItem("lingua");

const Azienda = () => {
  return (
    <>
      <Helmet>
        <title>{Traduci(lingua, "seo_azienda_title")}</title>
        <meta name="description" content={Traduci(lingua, "seo_azienda_description")} />
      </Helmet>
      <Testata
        sopraTitolo={Traduci(lingua, "azienda_testata_pre")}
        pagina="azienda"
        titolo={Traduci(lingua, "azienda_testata_title")}
      />
      {/* <Gallery6Scroll /> */}
      <div className="pt pb">
        <div className="container">
          <div className="sedi">
            <div className="intro text-center">
              <h2>Sovere (BG) Italia, Headquarters</h2>
              {Traduci(lingua, "azienda_testata_intro")}
            </div>
          </div>
        </div>
      </div>

      <SectionOdometer
        numeri={[
          {
            numero: 30,
            titolo: Traduci(lingua, "azienda_stats_collaboratori"),
          },
          {
            numero: 2000,
            unita: "mq",
            titolo: Traduci(lingua, "azienda_stats_magazzino"),
          },
          {
            numero: 2500,
            unita: "mq",
            titolo: Traduci(lingua, "azienda_stats_materie_prime"),
          },
          {
            numero: 800,
            unita: "mq",
            titolo: Traduci(lingua, "azienda_stats_uffici"),
          },
          { numero: 500, titolo: Traduci(lingua, "azienda_stats_styling") },
          { numero: 1500, titolo: Traduci(lingua, "azienda_stats_prototipi") },
        ]}
      />

      <div className="mb">
        <Gallery
          immagini={[
            "L1040763",
            "L1040778",
            "L1040782",
            "L1040781",
            "L1040785",
            "L1040769",
            "L1050151",
            "L1040788",
            "L1040773",
          ]}
        />
      </div>

      <ParallaxBanner
        layers={[
          { image: "/img/azienda/parallax_azienda.jpg", speed: -18 },
          {
            speed: -18,
            children: (
              <div className="parallax-overlay">
                <div className="container">
                  <div className="parallax-caption">
                    <h2>{Traduci(lingua, "azienda_parallax_title")}</h2>
                    <p className="lead">
                      {Traduci(lingua, "azienda_parallax_text")}
                    </p>
                  </div>
                </div>
              </div>
            ),
          },
        ]}
        className="parallax-banner"
      ></ParallaxBanner>

      {/* <div data-aos="fade-in" className="pt pb">
        <div className="container">
          <div className="intro">
            <h2>Silusi per il sociale</h2>
            <h3>
              Siamo da sempre impegnati in attività che possano coniugare una
              crescita economica e lo sviluppo sociale sia nel nostro territorio
              che all’estero.
            </h3>

            <div className="accordion">
              <div className="accordion-top">Silusi per lo sport</div>
              <div className="accordion-bottom">
                <p>
                  Noi di Silusi siamo sportivi nell’animo, già dagli anni ‘90
                  siamo stati sponsor per diverse realtà sportive. Per noi sport
                  significa aggregazione, crescita e sapere accettare le sfide,
                  valori che si possono ritrovare in Silusi.
                </p>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion-top">Silusi per la comunità</div>
              <div className="accordion-bottom">
                <p>
                  Noi di Silusi sappiamo che è importante aiutare la comunità
                  nella quale si opera.
                </p>

                <p>
                  Per questo nel 1991, anno in cui siamo approdati in Romania,
                  entrati in contatto con la comunità delle Suore di Gesù
                  Redendore e la la Fundatia “Victorine Le Dieu”, nella persona
                  di Michela Martinello, abbiamo deciso di condividere un
                  progetto di sostegno ai bambini orfani o con situazioni
                  familiari problematiche, ragazze madri, famiglie e bisognosi.
                </p>

                <p>
                  Ci siamo impegnati fin da subito nel rendere noti gli
                  obiettivi e le intenzioni umanitarie della Comunità in Italia,
                  creando così una rete di contatti tra numerose famiglie
                  italiane e le Sorelle; questo scambio si è poi concretizzato
                  in iniziative di adozione a distanza di bambini rumeni e aiuti
                  in denaro per la Comunità.
                </p>

                <p>
                  Grazie al sostegno ed al costante interessamento, la
                  Fondazione “Victorine Le Dieu”: è stata messa in contatto con
                  un gruppo di volontari alpini e nel 1997 è stato possibile
                  realizzare un nuovo centro di accoglienza con sede a Slanic
                  Moldova, località termale molto rinomata situata vicino ad
                  Onesti. Il centro ospita circa 30 bambini. Questo nuovo
                  edificio si affianca ora alla casa situata ad Onesti, dove le
                  Sorelle si prendono cura di ragazze madri e famiglie
                  bisognose.
                </p>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion-top">Tutti a scuola da Silusi</div>
              <div className="accordion-bottom">
                <p>
                  Nella sede in Romania è tutt’oggi attiva la scuola di
                  addestramento dove le cucitrici hanno l’opportunità di
                  imparare il lavoro e di fare un’esperienza remunerata per la
                  durata di un mese sotto l’attenta guida di personale
                  qualificato» spiegano dalla Silusi.
                </p>

                <p>
                  Al termine del periodo di prova, le lavoratrici ritenute
                  idonee e competenti ricevono la qualifica e vengono inserite
                  nella linea di confezione.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb" data-aos="fade-down">
        <div className="container">
          <div className="row">
            <div className="pr">
              <p className="lead">
                La nostra realtà nasce nel 1976 dalla visione del fondatore
                Angelo Sigorini che all’epoca avviò un’azienda familiare pronta
                per una nuova esperienza nel mondo tessile.
              </p>
              <p>
                Dopo anni di lavoro nell’azienda di Sovere, nel 1983 Pier Luigi
                Lumina, la moglie Raffaella insieme a Giorgio, Clara e Matteo
                aprono ufficialmente la Silusi spostando il core business dalla
                produzione di lana a capi imbottiti di ovatta, per poi
                specializzarsi nelle lavorazioni più tecniche con il Gore-Tex.
              </p>
              <p>
                Il business cresce fino a portare la nostra azienda a produrre
                anche per il mercato estero con tecniche e tecnologie sempre più
                all’avanguardia e ci siamo specializzati nella realizzazione di
                piumini, giacche a vento e giubbotteria in genere diventando
                terzista per capi di abbigliamento dei brand dell’alta moda e
                dello sport.
              </p>
            </div>
            <div className="">
              <div className="swiper silusi-cards">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <img src="/img/azienda/slide_L1050054.jpg" alt="" />
                  </div>
                  <div className="swiper-slide">
                    <img src="/img/azienda/slide_L1050114.jpg" alt="" />
                  </div>
                </div>
              </div>

              <script src="https://unpkg.com/swiper/swiper-bundle.min.js"></script>
            </div>
          </div>
        </div>
      </div> */}

      <Accordion titolo={Traduci(lingua, "azienda_accordion_materiali")}>
        <Gallery
          immagini={[
            "L1050027",
            "L1050020",
            "L1050062",
            "L1050050",
            "L1050049",
            "L1050047",
          ]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "azienda_accordion_progettazione")}>
        <Gallery
          immagini={["L1050134", "L1050139", "L1050144"]}
          video={["Riunione4", "Riunione3", "Riunione"]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "azienda_accordion_prototipia")}>
        <Gallery
          immagini={[
            "L1050099",
            "L1050086",
            "L1050132",
            "L1050012",
            "L1050008",
            "L1050005",
            "L1050127",
            "L1040819",
            "L1050232",
            "L1040881",
          ]}
          video={[
            "Ultrasuoni",
            "Taglierina2",
            "Saldatura",
            "Laser2",
            "Cucitura4",
          ]}
        />
      </Accordion>

      <Accordion
        titolo={Traduci(lingua, "azienda_accordion_controllo_qualita")}
      >
        <Gallery immagini={["L1040855", "L1040865", "L1040849"]} />
      </Accordion>

      <Accordion
        titolo={Traduci(lingua, "azienda_accordion_magazzino_capi_finiti")}
      >
        <Gallery
          immagini={[
            "L1040877",
            "L1050042",
            "L1040837",
            "L1050036",
            "L1040836",
            "L1050032",
          ]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "azienda_accordion_amministrazione")}>
        <Gallery immagini={["L1080419", "L1040788", "L1040787"]} />
      </Accordion>

      <div className="pt "></div>
      {/* <Cta /> */}
    </>
  );
};

export default Azienda;
