import React, { useEffect, useRef, useState } from "react";
import YoutubeBackground from "react-youtube-background";

const YoutubePlayer = ({ videoUrl }) => {
  const videoRef = useRef(null); 
  

  return (
    <div className="youtube-container">
      <YoutubeBackground
        videoId={videoUrl} /* default -> null */
        aspectRatio={"16:9"} /* default -> "16:9" */
        playerOptions={{ mute: 1, loop: 8, autoplay: 0 }}
        className={"video-yt"} /* default -> null */
        nocookie={true}
      > 
        <div className="video-home-overlay"> 
        </div>      
      </YoutubeBackground>
    </div>
  );
};

export default YoutubePlayer;
