import React from "react";
import BannerBW from "../componenti/BannerBW";
import Cta from "../componenti/Cta";
import Gallery from "../componenti/Gallery/Gallery";
import Gallery6Scroll from "../componenti/Gallery/Gallery6Scroll";
import GalleryH from "../componenti/Gallery/GalleryH";
import Testata from "../componenti/Testata";
import { ParallaxBanner } from "react-scroll-parallax";
import Plus from "../componenti/Plus";
import Accordion from "../componenti/Accordion/Accordion";
import VideoPlayer from "../componenti/VideoPlayer";
import SectionOdometer from "../componenti/SectionOdometer";
import SediOdometer from "../componenti/SediOdometer";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";

const lingua = localStorage.getItem("lingua");

const SediProduttive = () => {
  return (
    <>
      <Helmet>
        <title>{Traduci(lingua, "seo_sedi_produttive_title")}</title>
        <meta
          name="description"
          content={Traduci(lingua, "seo_sedi_produttive_description")}
        />
      </Helmet>
      <Testata
        sopraTitolo={Traduci(lingua, "romania_pre")}
        pagina="made in Europe - Romania"
        titolo={Traduci(lingua, "romania_testata_title")}
      />
      <div className="pt pb">
        <div className="container">
          <div className="intro sedi-europe text-center mb">
            <h4>{Traduci(lingua, "romania_stats_intro")}</h4>
            <ul>
              <li>Lusi Union</li>
              <li>Silmadum</li>
              <li>Lubosil</li>
              <li>Celitex</li>
            </ul>
          </div>

          <div className="sedi">
            <div className="intro text-center">
              <h3>Lusi Union - Tg Trotus, Jud Bacau, Romania</h3>

              {Traduci(lingua, "romania_intro")}
            </div>
          </div>
        </div>
      </div>

      <SectionOdometer
        numeri={[
          { numero: 14, titolo: Traduci(lingua, "romania_stats_linee") },
          {
            numero: 650,
            titolo: Traduci(lingua, "romania_stats_collaboratori"),
          },
          { numero: 185000, titolo: Traduci(lingua, "romania_stats_capi") },
        ]}
      />

      <div className="pb sedi-europa">
        <div className="container">
          <div className="sede2">
            <h3>Lusi Union Silusi S.r.l.</h3>
            <p>TG Trotus - Jud Bacau - Romania</p>
          </div>
          <div className="row lista-sedi">
            <div className="col-9">
              <SediOdometer
                numeri={[
                  { numero: 6, titolo: Traduci(lingua, "romania_stats_linee") },
                  {
                    numero: 340,
                    titolo: Traduci(lingua, "romania_stats_collaboratori"),
                  },
                  {
                    numero: 96000,
                    titolo: Traduci(lingua, "romania_stats_capi"),
                  },
                ]}
              />
            </div>
          </div>

          <div className="sede2">
            <h3>Silmadum Fashion S.r.l.</h3>
            <p>Adjud - Vrancea - Romania</p>
          </div>
          <div className="row lista-sedi">
            <div className="col-9">
              <SediOdometer
                numeri={[
                  { numero: 2, titolo: Traduci(lingua, "romania_stats_linee") },
                  {
                    numero: 40,
                    titolo: Traduci(lingua, "romania_stats_collaboratori"),
                  },
                  {
                    numero: 11500,
                    titolo: Traduci(lingua, "romania_stats_capi"),
                  },
                ]}
              />
            </div>
          </div>

          <div className="sede2">
            <h3>Lubosil Conf.</h3>
            <p>
              Via Republicil n.2
              <br />
              Buhusi Reg. Bacau-Romania
            </p>
          </div>
          <div className="row lista-sedi">
            <div className="col-9">
              <SediOdometer
                numeri={[
                  { numero: 3, titolo: Traduci(lingua, "romania_stats_linee") },
                  {
                    numero: 110,
                    titolo: Traduci(lingua, "romania_stats_collaboratori"),
                  },
                  {
                    numero: 32000,
                    titolo: Traduci(lingua, "romania_stats_capi"),
                  },
                ]}
              />
            </div>
          </div>

          <div className="sede2">
            <h3>Celitex S.r.l.</h3>
            <p>Roman - Romania</p>
          </div>
          <div className="row lista-sedi">
            <div className="col-9">
              <SediOdometer
                numeri={[
                  { numero: 3, titolo: Traduci(lingua, "romania_stats_linee") },
                  {
                    numero: 160,
                    titolo: Traduci(lingua, "romania_stats_collaboratori"),
                  },
                  {
                    numero: 45500,
                    titolo: Traduci(lingua, "romania_stats_capi"),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <Gallery
        immagini={[
          "L1140043",
          "20220225_102923",
          "L1130612",
          "L1130631",
          "L1130605",
          "L1130665",
        ]}
      />

      <div className="pt pb">
        <div className="carrellata">
          <div className="container">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
                maxWidth: "900px",
              }}
            >
              <video
                onLoadStart="this.volume=0.2"
                muted
                className="video-player"
                controls
                preload="auto"
              >
                <source src="/video/carrellata.mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
      <Accordion titolo={Traduci(lingua, "romania_accordion_materiali")}>
        <Gallery
          immagini={[
            "L1130677",
            "L1130522",
            "L1130528",
            "L1130540",
            "L1130552",
            "L1130561",
            "L1130573",
            "L1130581",
            "L1130582",
          ]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_taglio")}>
        <Gallery
          immagini={[
            "L1130610",
            "L1130612",
            "L1130625",
            "L1130682",
            "L1130759",
            "L1130728",
            "L1130737",
            "L1130740",
            "L1130715",
            "L1130747",
            "L1130746",
            "L1130764",
            "L1130526",
            "L1000676",
          ]}
          video={["L1130753", "L1130765", "L1130733", "L1130611-edit"]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_laser")}>
        <Gallery
          immagini={["L1140016", "L1140009", "L1130990", "L1140003"]}
          video={["Laser2", "L1140001", "L1140006", "L1140002", "L1140007"]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_linea_produttiva")}>
        <Gallery
          immagini={[
            "L1130811",
            "L1130820",
            "L1130824",
            "L1130826",
            "L1130852",
            "L1130855",
            "L1130858",
            "L1130906",
            "L1130911",
          ]}
          video={["L1140023", "L1130845", "20220225_133810"]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_piuma")}>
        <Gallery
          immagini={["L1130785", "L1130791", "L1130803"]}
          video={[
            "L1130790",
            "L1130777",
            "L1130788",
            "L1130789",
            "L1130807",
            "L1130808",
          ]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_termosaldatura")}>
        <Gallery
          immagini={[
            "L1130870",
            "L1130879",
            "L1130876",
            "L1130899",
            "L1130896",
          ]}
          video={[
            "L1130872",
            "L1130873",
            "L1130874",
            "L1130880",
            "L1130881",
            "L1130894",
            "L1130895",
          ]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_incollaggio")}>
        <Gallery
          immagini={["L1130954", "L1130959", "L1130965", "L1050075"]}
          video={["L1130966", "Pressa"]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_template")}>
        <Gallery
          immagini={[
            "L1140017",
            "L1130967",
            "L1130976",
            "L1130974",
            "L1130983",
          ]}
          video={["L1130969"]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_finissaggio")}>
        <Gallery
          immagini={["L1140030", "L1140024", "finissaggio1"]}
          video={["L1140028", "L1140027", "L1140026"]}
        />
      </Accordion>

      <Accordion
        titolo={Traduci(lingua, "romania_accordion_controllo_qualita")}
      >
        <Gallery
          immagini={[
            "L1130916",
            "L1130937",
            "L1130817",
            "L1130903",
            "L1130939",
          ]}
          video={["L1130932"]}
        />
      </Accordion>

      <Accordion titolo={Traduci(lingua, "romania_accordion_capi_finiti")}>
        <Gallery
          immagini={["L1130930", "L1130927", "L1130925", "L1130942"]}
          video={["L1130931", "L1130940"]}
        />
      </Accordion>

      <div className="pb"></div>
    </>
  );
};

export default SediProduttive;
