import React from "react";
import ModuloContattaci from "../componenti/Form/ModuloContattaci";
import Testata from "../componenti/Testata";
import { GoMail } from "react-icons/go";
import { Traduci } from "../traduzioni/language";
import { Helmet } from "react-helmet";
const lingua = localStorage.getItem("lingua");

const Contatti = () => {
  return (
    <>
    <Helmet>
      <title>{Traduci(lingua, "seo_contatti_title")}</title>
      <meta
        name="description"
        content={Traduci(lingua, "seo_contatti_description")}
      />
    </Helmet>

      <Testata
        sopraTitolo={Traduci(lingua, "contatti_pre")}
        pagina="contatti"
        titolo={Traduci(lingua, "contatti_testata_title")}
      />

      <div className="contatti-email pb">
        <div className="container">
          <h3>
          {Traduci(lingua, "contatti_office_title")}
          </h3>
          <div className="email_list">
            <p>
              <strong>{Traduci(lingua, "contatti_column_1")}</strong>
              <a href="mailto:matteo.cotugno@silusi.com">
                <GoMail />
                matteo.cotugno@silusi.com
              </a>
            </p>
            <p>
              <strong>{Traduci(lingua, "contatti_column_2")}</strong>
              <a href="mailto:Michela.Domenighini@silusi.com">
                <GoMail />
                michela.domenighini@silusi.com
              </a>
              <a href="mailto:alessandro.lumina@silusi.com">
                <GoMail />
                alessandro.lumina@silusi.com
              </a>
              <a href="mailto:angelo.cotugno@silusi.com">
                <GoMail />
                angelo.cotugno@silusi.com
              </a>
            </p>
            <p>
              <strong>{Traduci(lingua, "contatti_column_3")}</strong>
              <a href="mailto:greta.sigorini@silusi.com">
                <GoMail />
                greta.sigorini@silusi.com
              </a>
              <a href="mailto:giorgio.sigorini@silusi.com">
                <GoMail />
                giorgio.sigorini@silusi.com
              </a>
            </p>
            <p>
              <strong>{Traduci(lingua, "contatti_column_4")}</strong>
              <a href="mailto:eleonora.girelli@silusi.com">
                <GoMail />
                eleonora.girelli@silusi.com
              </a>
              <a href="mailto:patrizia.bosio@silusi.com">
                <GoMail />
                patrizia.bosio@silusi.com
              </a>
            </p>
            <p>
              <strong>{Traduci(lingua, "contatti_column_5")}</strong>
              <a href="mailto:michela.domenighini@silusi.com">
                <GoMail />
                michela.domenighini@silusi.com
              </a>
            </p>
            <p>
              <strong>{Traduci(lingua, "contatti_column_6")}</strong>{" "}
              <a href="mailto:marino.moretti@silusi.com">
                <GoMail />
                marino.moretti@silusi.com
              </a>
            </p>
            <p>
              <strong>{Traduci(lingua, "contatti_column_7")}</strong>
              <a href="mailto:alda.pe@silusi.com">
                <GoMail />
                alda.pe@silusi.com
              </a>
              <a href="mailto:clara.sigorini@silusi.com">
                <GoMail />
                clara.sigorini@silusi.com
              </a>
            </p>
            <p>
              <strong>{Traduci(lingua, "contatti_column_8")}</strong>{" "}
              <a href="mailto:giambi.moretti@silusi.com">
                <GoMail />
                giambi.moretti@silusi.com
              </a>
            </p>
            <p>
              <strong>{Traduci(lingua, "contatti_column_9")}</strong>{" "}
              <a href="mailto:luana.sonvilla@silusi.com">
                <GoMail />
                luana.sonvilla@silusi.com
              </a>
              <a href="mailto:federica.vidilini@silusi.com">
                <GoMail />
                federica.vidilini@silusi.com
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="contatti-list pb">
        <div className="container">
          <div className="thumbnail">
            <img src="../images/struttura/grid.png" alt="" />
          </div>

          <div className="contatti-wrapper">
            <div className="contatti_italia">
              <h3>ITALY</h3>
              <ul>
                <li>
                  <h4>SILUSI S.R.L.</h4>
                  <p>
                    <strong>
                      Via San Rocco n.19
                      <br />
                      24060 - Sovere(BG)&nbsp;
                    </strong>
                  </p>
                  <br />
                  <p>
                    Tel. 035/982666
                    <br />
                    Fax 035/982656
                  </p>
                  <p>
                    CF-PIVA: 01573320163
                    <br />
                    Reg.Imp.BG n.25958/25007 Cap.Soc. Euro 101.490,00 i.v.&nbsp;
                  </p>
                  e-mail:&nbsp;
                  <a href="mailto:info@silusi.com">info@silusi.com</a>
                </li>
              </ul>
            </div>

            <div className="contatti_romania">
              <h3>ROMANIA</h3>
              <ul className="indirizzi-romania">
                <li>
                  <h4>Lusi Union Silusi S.r.l.</h4>
                  <p>
                    TG Trotus - <br />
                    Jud Bacau - <br />
                    Romania
                    <br />
                    Tel. +40 [234] 347.169 -<br />
                    Fax +40 [234] 347.168
                  </p>
                </li>
                <li>
                  <h4>Silmadum Fashion S.r.l.</h4>
                  <p>
                    Adjud
                    <br />
                    Vrancea <br />
                    Romania
                  </p>
                </li>
                <li>
                  <h4>Lubosil Conf. S.r.l.</h4>
                  <p>
                    Via REPUBLICII Nr.2 <br />
                    BUHUSI Reg. BACAU
                  </p>
                </li>
                <li>
                  <h4>Celitex S.r.l.</h4>
                  <p>Roman, Romania</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="pt pb" data-aos="fade-down" style={{display:"none"}}>
        <div className="container">
          <div className="intro text-center">
            <h3 className="mb0">
              Ti affiancherremo. dalla progettazione alla realizzazione
            </h3>
            <p className="lead">
              offrendoti esperienza, tecnologia e assistenza in ogni fase di
              lavorazione.
            </p>
          </div>
          <div className="modulo-wrapper">
            <ModuloContattaci />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contatti;
