import React from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
gsap.registerPlugin(ScrollTrigger);

const GalleryH = ({ immagini }) => {
  // populate images
  for (var i = 1; i <= 6; i++) {
    gsap.set(".t" + i + " .thumbImg", {
      background: "black",
    });
  }

  let m1 = useRef(null);
  let m2 = useRef(null);
  let m3 = useRef(null);
  const text = useRef({ value: 1 });

  useEffect(() => {
    // setup parallax on those images
    ScrollTrigger.create({
      trigger: ".thumbs",
      start: "top bottom",
      end: "bottom top",
      onUpdate: (s) => {
        gsap.to(".thumbImg", {
          duration: 0.6,
          ease: "expo",
          y: (i) => {
            // use progress to define the y position
            return i % 2 == 0
              ? gsap.utils.interpolate(100, -300, s.progress)
              : gsap.utils.interpolate(350, -650, s.progress);
          },
          backgroundPosition: (i) => {
            // and to define the background offset
            var str =
              i % 2 == 0
                ? gsap.utils.interpolate(-200, 0, s.progress)
                : gsap.utils.interpolate(-500, 0, s.progress);
            return "50% " + str + "px";
          },
        });
      },
    });

    // fade out as you scroll downward
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".section",
          start: "50px top",
          end: "150px top",
          scrub: 0.5,
        },
      })
      .to(".dir", { opacity: 0, ease: "sine.inOut" });
  }, [text]);

  return (
    <>
      <div className="galleryh ">
        <div className="txtBlock">
          <h1>Lorem Ipsum</h1>
          <p>
            Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat.
          </p>

          <p>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </p>
        </div>
        <div className="thumbs">
          {immagini.map((img, indx) => {
            return (
              <div className="thumb" key={indx}>
                <div
                  className="thumbImg"
                  style={{ background: `url(/images/${img}.jpg)` }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GalleryH;
