import React from "react";
import { motion } from "framer-motion";
import Fancybox from "../Animazioni/fancybox.js";
import uuid from "react-uuid";
import { MdPlayCircle } from "react-icons/md";

const galleryVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.1, delayChildren: 0, staggerChildren: 0.1 },
  },
};

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const Gallery = ({ immagini, titolo, video, customClass }) => {
  const relFancybox = uuid();

  return (
    <div className={`pt2 pb2 ${customClass}`}>
      <div className="container">
        <motion.div
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.3 }}
          variants={galleryVariants}
          initial="hidden"
          className="gallery-wrapper"
        >
          {titolo && <h2>{titolo}</h2>}
          {/* {titolo ? {titolo} : ""} */}
          <div className="gallery-grid">
            {immagini.map((img, indiceimg) => {
              return (
                <motion.div
                  key={indiceimg}
                  className="gallery-inner-img"
                  variants={itemVariants}
                >
                  <Fancybox>
                    <a
                      data-fancybox={relFancybox}
                      href={`/images/${img}.jpg`}
                      style={{ backgroundImage: `url(/images/${img}.jpg)` }}
                    >
                      {/* <img src={`/images/${img}.jpg`} alt=" " /> */}
                    </a>
                  </Fancybox>
                </motion.div>
              );
            })}

            {video &&
              video.map((vid) => {
                return (
                  <motion.div
                    key={vid}
                    className="gallery-inner-img"
                    variants={itemVariants}
                  >
                    <Fancybox>
                      <a
                        className="video-link"
                        data-width="1778"
                        and
                        data-height="1000"
                        data-fancybox={relFancybox}
                        href={`/video/${vid}.mp4`}
                        style={{
                          backgroundImage: `url(/video/thumb/${vid}.jpg)`,
                        }}
                      >
                        {/* <img src={`/video/thumb/${vid}.jpg`} alt=" " /> */}
                        <div className="video-icon-play">
                          <MdPlayCircle />
                        </div>
                      </a>
                    </Fancybox>
                  </motion.div>
                );
              })}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Gallery;
